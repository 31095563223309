<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Formaty</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Formaty</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Formaty</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/format/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editFormat"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #expand>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Edycja formatu</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Grupa
                                </label>
                                <div class="col-sm-10">
                                  <Multiselect
                                    v-model="formatObj.format_group"
                                    mode="single"
                                    placeholder="Grupa"
                                    track-by="name"
                                    label="name"
                                    :close-on-select="true"
                                    :search="true"
                                    :options="formatGroups"
                                  >
                                  </Multiselect>
                                  <div
                                    v-if="errorsAdminData?.data?.format_group"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.format_group[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Rozmiar
                                </label>
                                <div class="col-sm-10 flex-inputs">
                                  <div>
                                    <input
                                      v-model="formatObj.size_x"
                                      name="size_x"
                                      type="number"
                                      class="form-control inputClass"
                                      placeholder="Rozmiar X"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.size_x"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.size_x[0] }}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <input
                                      v-model="formatObj.size_y"
                                      name="size_y"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Rozmiar Y"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.size_y"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.size_y[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-10">
                                  <div class="checkbox checkbox-primary">
                                    <label>
                                      <input
                                        v-model="formatObj.sheet_format"
                                        name="sheet_format"
                                        type="checkbox"
                                      />
                                      <span>Format arkusza</span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div class="hr-line-dashed"></div>

                              <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">
                                  <button class="btn btn-white" @click="cancel">
                                    Anuluj
                                  </button>
                                  <button
                                    @click="saveEdit"
                                    class="btn btn-primary"
                                  >
                                    Zapisz
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #item-actions="item">
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.size_x + 'x' + item.size_y;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deleteformat(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "FormatsList",
  mixins: [],
  components: {
    Modal,
    Multiselect,
  },
  props: {},

  setup() {
    const store = useStore();
    const deleteFormatsData = computed(
      () => store.getters.getDeleteFormatsData
    );
    const formatData = computed(() => store.getters.getFormatData);
    const updateFormatsData = computed(
      () => store.getters.getUpdateFormatsData
    );
    const formatsData = computed(() => store.getters.getFormatsData);
    const formatGroupsData = computed(
      () => store.getters.getFormatGroupsAdminData
    );
    const formatObj = ref({
      sheet_format: false,
    });
    let headers = [
      { text: "ID", value: "pk", sortable: true, search: true },
      { text: "Rozmiar X", value: "size_x", sortable: true, search: true },
      { text: "Rozmiar Y", value: "size_y", sortable: true, search: true },
      { text: "Grupa", value: "format_group", sortable: true, search: true },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const formatGroups = ref([]);
    const showModal = ref(false);
    const deleteName = ref("");
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const getFormats = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getFormats", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const deleteformat = (pk) => {
      showModal.value = false;
      store.dispatch("deleteFormats", {
        pk: pk,
      });
    };
    const saveEdit = () => {
      store.dispatch("updateFormats", formatObj.value);
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editFormat = (item) => {
      cancel();
      store.dispatch("getFormat", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getFormats();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getFormats();
    };

    const updateSort = () => {
      search.value = true;
    };
    store.dispatch("getFormatGroupsAdmin");
    getFormats();

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getFormats();
          }, 1000);
        }
      }
    );

    watch(
      () => formatsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => formatData.value,
      function (newValue) {
        formatObj.value = newValue.data;
      }
    );

    watch(
      () => deleteFormatsData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Format został usuniety",
          });
        }
        getFormats();
      }
    );

    watch(
      () => updateFormatsData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getFormats();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => formatGroupsData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          formatGroups.value.push({
            name: "[" + element.pk + "] " + element.name_pl,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getFormats();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      deleteformat,
      deleteName,
      formatObj,
      cancel,
      editFormat,
      saveEdit,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
      formatGroups,
    };
  },
  computed: {},
};
</script>
