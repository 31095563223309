<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Klienci</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/clients/list">Klienci</router-link>
        </li>
        <li class="active">
          <strong>Nowy</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Dodanie nowego klienta</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Adres e-mail</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.user.email"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Adres e-mail"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.user?.email"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.user.email[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Hasło</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.user.password"
                    type="password"
                    class="form-control inputClass"
                    placeholder="Hasło"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.user?.password"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.user.password[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Powtórz hasło</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.user.password2"
                    type="password"
                    class="form-control inputClass"
                    placeholder="Powtórz hasło"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.user?.password2"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.user.password2[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Główne konto</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="clientObj.main_account"
                    mode="single"
                    placeholder="Główne konto"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="clientsOptions"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.main_account"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.main_account[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <label class="control-label col-sm-2">Sklep</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="clientObj.company_localization"
                    mode="single"
                    placeholder="Sklep"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="countryList"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.main_account"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.main_account[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Imię</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.user.first_name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Imię"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.user?.first_name"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.user.first_name[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Nazwisko</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.user.last_name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwisko"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.user?.last_name"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.user.last_name[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa firmy</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.company_name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa firmy"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.company_name"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.company_name[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">NIP</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.tax_id"
                    type="text"
                    class="form-control inputClass"
                    placeholder="NIP"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.tax_id" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.tax_id[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <label class="control-label col-sm-2">Numer telefonu</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.telephone"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Numer telefonu"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.telephone"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.telephone[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Adres</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.address"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Adres"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.address" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.address[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Numer budynku</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.building_number"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Numer budynku"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.building_number"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.building_number[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Numer mieszkania</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.suite_number"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Numer mieszkania"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.suite_number"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.suite_number[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Miasto</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.city"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Miasto"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.city" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.city[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Kod pocztowy</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.zip_code"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Kod pocztowy"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.zip_code" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.zip_code[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Kraj</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="clientObj.country"
                    mode="single"
                    placeholder="Kraj"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="countriesOptions"
                  >
                  </Multiselect>
                  <div v-if="errorsAdminData?.data?.country" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.country[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Dodatkowy adres dostawy</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa firmy</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.company_name_2"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa firmy"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.company_name_2"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.company_name_2[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <label class="control-label col-sm-2">Numer telefonu</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.telephone_2"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Numer telefonu"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.telephone_2"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.telephone_2[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Adres</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.address_2"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Adres"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.address_2"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.address_2[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Numer budynku</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.building_number_2"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Numer budynku"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.building_number_2"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.building_number_2[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Numer mieszkania</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.suite_number_2"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Numer mieszkania"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.suite_number_2"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.suite_number_2[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Miasto</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.city_2"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Miasto"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.city_2" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.city_2[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Kod pocztowy</label>
                <div class="col-sm-10">
                  <input
                    v-model="clientObj.zip_code_2"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Kod pocztowy"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.zip_code_2"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.zip_code_2[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Kraj</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="clientObj.country_2"
                    mode="single"
                    placeholder="Kraj"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="countriesOptions"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.country_2"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.country_2[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/ips">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "ClientsCreate",
  mixins: [],
  components: {
    Multiselect,
  },
  props: {},

  setup() {
    const clientObj = ref({
      user: {
        email: "",
        password: "",
        password2: "",
      },
    });

    const countryList = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
    ]);

    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const createClientData = computed(() => store.getters.getCreateClientData);
    const adminClientsData = computed(() => store.getters.getAdminClientsData);
    const countriesData = computed(() => store.getters.getCountriesData);
    const clientsOptions = ref([]);
    const countriesOptions = ref([]);

    const create = () => {
      showErrors.value = true;
      store.dispatch("createClient", clientObj.value);
    };

    store.dispatch("getAdminClients");
    store.dispatch("getCountriesList");

    watch(
      () => createClientData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router
            .push({ name: "ClientsList", path: "/clients/list" })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Nowy klient został dodany",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    watch(
      () => adminClientsData.value,
      function (newValue) {
        newValue.data.results?.forEach((element) => {
          clientsOptions.value.push({
            name: "[" + element.pk + "] " + element.full_name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => countriesData.value,
      function (newValue) {
        console.log(newValue);
        newValue.data.data?.forEach((element) => {
          countriesOptions.value.push({
            name: element.name_pl,
            value: element.code,
          });
        });
      }
    );

    return {
      countryList,
      clientObj,
      errorsAdminData,
      showErrors,
      create,
      clientsOptions,
      countriesOptions,
    };
  },
  computed: {},
};
</script>
