<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Tłumaczenia</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Tłumaczenia</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Tłumaczenia</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <button @click="exportFile" class="btn btn-sm btn-primary">
                  <i class="fa fa-file-excel-o"></i>
                  Exportuj
                </button>
                <button
                  @click="importFile = true"
                  class="btn btn-sm btn-primary"
                >
                  <i class="fa fa-cloud-upload"></i>
                  Importuj
                </button>
              </div>
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editPlate"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #item-actions="item">
                    <div v-if="item.isNew">
                      <button
                        @click="addNewSend"
                        class="btn btn-success btn-xs"
                      >
                        <i class="fa fa-check"></i>
                      </button>
                      <button @click="cancelNew" class="btn btn-danger btn-xs">
                        <span class="fa fa-times"></span>
                      </button>
                    </div>
                    <div v-else>
                      <button
                        v-if="item.isEdit"
                        @click="editSend"
                        class="btn btn-success btn-xs"
                      >
                        <i class="fa fa-check"></i>
                      </button>
                      <button
                        v-if="item.isEdit"
                        @click="cancelEdit"
                        class="btn btn-danger btn-xs"
                      >
                        <span class="fa fa-times"></span>
                      </button>
                      <button
                        v-if="!item.isEdit"
                        @click="editRow(item)"
                        class="btn btn-warning btn-xs"
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                      <button
                        v-if="!item.isEdit"
                        @click.stop="
                          showModal = item.pk;
                          deleteName = item.name;
                        "
                        class="btn btn-danger btn-xs"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </template>
                  <template #item-pl="item">
                    <div v-if="item.isNew || item.isEdit">
                      <div class="col-sm-10">
                        <input
                          v-model="translateObj.pl"
                          name="pl"
                          type="text"
                          class="form-control inputClass"
                          required="required"
                        />
                        <div v-if="errorsAdminData?.data?.pl" class="has-error">
                          <span class="help-block">
                            {{ errorsAdminData.data.pl[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span v-else>
                      {{ item.pl }}
                    </span>
                  </template>
                  <template #item-en="item">
                    <div v-if="item.isNew || item.isEdit">
                      <div class="col-sm-10">
                        <input
                          v-model="translateObj.en"
                          name="en"
                          type="text"
                          class="form-control inputClass"
                          required="required"
                        />
                        <div v-if="errorsAdminData?.data?.en" class="has-error">
                          <span class="help-block">
                            {{ errorsAdminData.data.en[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span v-else>
                      {{ item.en }}
                    </span>
                  </template>
                  <template #item-cpde="item">
                    <div v-if="item.isNew || item.isEdit">
                      <div class="col-sm-10">
                        <input
                          v-model="translateObj.cpde"
                          name="cpde"
                          type="text"
                          class="form-control inputClass"
                          required="required"
                        />
                        <div
                          v-if="errorsAdminData?.data?.cpde"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ errorsAdminData.data.cpde[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span v-else>
                      {{ item.cpde }}
                    </span>
                  </template>
                  <template #item-cpen="item">
                    <div v-if="item.isNew || item.isEdit">
                      <div class="col-sm-10">
                        <input
                          v-model="translateObj.cpen"
                          name="cpen"
                          type="text"
                          class="form-control inputClass"
                          required="required"
                        />
                        <div
                          v-if="errorsAdminData?.data?.cpen"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ errorsAdminData.data.cpen[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span v-else>
                      {{ item.cpen }}
                    </span>
                  </template>
                  <template #item-de="item">
                    <div v-if="item.isEdit" class="form-group">
                      <div class="col-sm-10">
                        <input
                          v-model="translateObj.de"
                          name="de"
                          class="form-control inputClass"
                          type="text"
                        />
                        <div v-if="errorsAdminData?.data?.de" class="has-error">
                          <span class="help-block">
                            {{ errorsAdminData.data.de[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span v-else>
                      {{ item.de }}
                    </span>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deleteTranslationsModels(showModal)"
    @callbackClose="showModal = false"
  />
  <div v-if="importFile" class="modal-dialog">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Import tłumaczeń</h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="excel_file" class="col-sm-2 control-label">
              Plik excel
            </label>
            <div class="col-sm-10">
              <div class="fileinput fileinput-new input-group">
                <div class="form-control">
                  <input
                    type="file"
                    name="file"
                    v-on:change="onFileChange"
                    multiple="multiple"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="importFile = false" class="btn btn-white">
            Anuluj
          </button>
          <button @click="importFileFn()" class="btn btn-primary">
            Importuj
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="importFile" class="modal-backdrop"></div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";

export default {
  name: "TranslationsModelsList",
  mixins: [],
  components: {
    Modal,
  },
  props: {},

  setup() {
    const store = useStore();
    const deleteTranslationsModelsData = computed(
      () => store.getters.getDeleteTranslationsModelsData
    );
    const createTranslationsModelsData = computed(
      () => store.getters.getCreateTranslationsModelsData
    );
    const translateData = computed(() => store.getters.gettranslateData);
    const updateTranslationsModelsData = computed(
      () => store.getters.getUpdateTranslationsModelsData
    );
    const translationsModelsData = computed(
      () => store.getters.getTranslationsModelsData
    );
    const importTMData = computed(() => store.getters.getImportTMData);
    const translateObj = ref({
      name: "",
      value: false,
    });
    const errorsAdminData = ref();
    let headers = [
      { text: "Nr", value: "pk" },
      { text: "Model", value: "model", sortable: true, search: true },
      { text: "ID", value: "id", sortable: true },
      { text: "Pole", value: "field", sortable: true },
      { text: "Polski", value: "pl", sortable: true },
      { text: "Niemiecki", value: "de", sortable: true },
      { text: "Angielski", value: "en", sortable: true },
      { text: "Niemiecki (de)", value: "cpde", sortable: true },
      { text: "Angielski (de)", value: "cpen", sortable: true },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const excelFile = ref("");
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const importFile = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const getTranslationsModels = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getTranslationsModels", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const deleteTranslationsModels = (pk) => {
      showModal.value = false;
      store.dispatch("deleteTranslationsModels", {
        pk: pk,
      });
    };
    const saveEdit = () => {
      store.dispatch("updateTranslationsModels", translateObj.value);
    };

    const exportFile = () => {
      store.dispatch("exportTranslationsModels");
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const importFileFn = () => {
      store.dispatch("importTranslationsModels", {
        excel_file: excelFile.value,
      });
    };

    const addNew = () => {
      for (const [key] of Object.entries(items.value)) {
        items.value[key].isEdit = false;
        items.value[key].isNew = false;
      }
      items.value.push({
        pk: items.value[items.value.length - 1]?.pk + 1,
        name: "",
        value: false,
        isNew: true,
        isEdit: true,
      });
    };

    const onFileChange = (e) => {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      excelFile.value = files[0];
    };

    const addNewSend = () => {
      store.dispatch("createTranslationsModels", translateObj.value);
    };

    const editSend = () => {
      store.dispatch("updateTranslationsModels", translateObj.value);
    };

    const cancelNew = () => {
      items.value.splice(-1);
      translateObj.value = {
        name: "",
        value: "",
      };
    };

    const cancelEdit = (pk) => {
      for (const [key, value] of Object.entries(items.value)) {
        items.value[key].isEdit = false;
        if (value.pk === pk) {
          items.value[key].isEdit = false;
          items.value[key].isNew = false;
        }
      }
    };

    const editRow = (item) => {
      var keyObj = false;
      for (const [key, value] of Object.entries(items.value)) {
        items.value[key].isEdit = false;
        if (value.pk === item.pk) {
          keyObj = key;
        }
      }

      if (keyObj) {
        translateObj.value = item;
        items.value[keyObj].isEdit = true;
      }
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getTranslationsModels();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getTranslationsModels();
    };

    const updateSort = () => {
      search.value = true;
    };

    getTranslationsModels();

    watch(
      () => translationsModelsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => translateData.value,
      function (newValue) {
        translateObj.value = newValue.data;
      }
    );

    watch(
      () => deleteTranslationsModelsData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Zmienna została usunieta",
          });
        }
        getTranslationsModels();
      }
    );

    watch(
      () => createTranslationsModelsData.value,
      function (newValue) {
        if (newValue.status === 201) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Zmienna została dodana",
          });
        }
        getTranslationsModels();
      }
    );

    watch(
      () => updateTranslationsModelsData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getTranslationsModels();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getTranslationsModels();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getTranslationsModels();
          isSearch.value = false;
        }
      }
    );

    watch(
      () => importTMData.value,
      function (newValue) {
        if (newValue?.status === 200) {
          getTranslationsModels();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaimportowane",
          });
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      deleteTranslationsModels,
      deleteName,
      translateObj,
      cancel,
      saveEdit,
      addNew,
      addNewSend,
      cancelNew,
      cancelEdit,
      editRow,
      errorsAdminData,
      editSend,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
      exportFile,
      importFile,
      importFileFn,
      onFileChange,
    };
  },
  computed: {},
};
</script>
