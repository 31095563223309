<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Dni wolne</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Dni wolne</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Dni wolne</h5>
          </div>
          <div class="ibox-content" v-if="calendarApp">
            <ScheduleXCalendar :calendar-app="calendarApp" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="
      'Czy na pewno chcesz usunąć dzień ustawowo wolny <b>' +
      deleteName +
      '</b> ?'
    "
    @callbackOk="deleteHoliday(deletePk)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { ScheduleXCalendar } from "@schedule-x/vue";
import { createCalendar, viewMonthGrid } from "@schedule-x/calendar";
import { createEventsServicePlugin } from "@schedule-x/events-service";
import "@schedule-x/theme-default/dist/index.css";
import Modal from "../caffeprint/Modal.vue";

export default {
  name: "HolidaysList",
  mixins: [],
  components: {
    ScheduleXCalendar,
    Modal,
  },
  props: {},

  setup() {
    const store = useStore();
    const holidaysData = computed(() => store.getters.getHolidaysData);
    const createHolidaysData = computed(
      () => store.getters.getCreateHolidaysData
    );
    const deleteHolidaysData = computed(
      () => store.getters.getDeleteHolidaysData
    );
    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const deletePk = ref();
    const loading = ref(true);
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    const eventsServicePlugin = createEventsServicePlugin();
    const calendarApp = createCalendar({
      views: [viewMonthGrid],
      defaultView: viewMonthGrid.name,
      plugins: [eventsServicePlugin],
      callbacks: {
        onClickDate(calendarEvent) {
          var holiday = calendarEvent.split("-");
          var newHoliday = holiday[2] + "." + holiday[1] + "." + holiday[0];
          store.dispatch("createHolidays", { holiday: newHoliday });
        },
        onEventClick(calendarEvent) {
          if (calendarEvent.source === "open_holidays_api") {
            deleteName.value = calendarEvent.title;
            deletePk.value = calendarEvent.id;
            showModal.value = true;
          } else {
            eventsServicePlugin.remove(calendarEvent.id);
            store.dispatch("deleteHolidays", { pk: calendarEvent.id });
          }
        },
      },
      events: [],
    });
    const getHolidays = async () => {
      await store.dispatch("getHolidays");
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const deleteHoliday = (pk) => {
      eventsServicePlugin.remove(pk);
      store.dispatch("deleteHolidays", { pk: pk });
      showModal.value = false;
    };

    getHolidays();

    watch(
      () => holidaysData.value,
      function (newValue) {
        newValue.data.forEach((element) => {
          var holiday = element.holiday.split(".");
          eventsServicePlugin.add({
            id: element.pk,
            source: element.source,
            title: element.label
              ? element.label
              : "Dzień wolny " + "[" + element.created_by + "]",
            start: holiday[2] + "-" + holiday[1] + "-" + holiday[0],
            end: holiday[2] + "-" + holiday[1] + "-" + holiday[0],
          });
        });
      }
    );

    watch(
      () => createHolidaysData.value,
      function (newValue) {
        if (newValue.status === 201) {
          var holiday = newValue.data.holiday.split(".");
          eventsServicePlugin.add({
            id: newValue.data.pk,
            title: "Dzień wolny",
            start: holiday[2] + "-" + holiday[1] + "-" + holiday[0],
            end: holiday[2] + "-" + holiday[1] + "-" + holiday[0],
          });
          store.dispatch("showToaster", {
            type: "success",
            content: "Dzień wolny został dodany",
          });
        }
      }
    );
    watch(
      () => deleteHolidaysData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Dzień wolny został usunięty",
          });
        }
      }
    );

    return {
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      deleteName,
      cancel,
      calendarApp,
      deleteHoliday,
      deletePk,
    };
  },
  computed: {},
};
</script>
