<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center m-t-lg">
          <div class="col-lg-3">
            <div class="ibox float-e-margins">
              <div class="ibox-title">
                <a>
                  <span class="label label-success pull-right">
                    Moje sprawy
                  </span>
                </a>
                <h5>Ilość Twoich spraw</h5>
              </div>
              <div class="ibox-content">
                <h1 class="no-margins">5</h1>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="ibox float-e-margins">
              <div class="ibox-title">
                <a>
                  <span class="label label-success pull-right">Wszystkie</span>
                </a>
                <h5>Wszystkie sprawy</h5>
              </div>
              <div class="ibox-content">
                <h1 class="no-margins">5</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  mixins: [],
  components: {},
  props: {},
  setup() {},
};
</script>
