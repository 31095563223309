<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Tworzenie formatu</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/format/list">Formaty</router-link>
        </li>
        <li class="active">
          <strong>Nowy</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Tworzenie formatu</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Grupa</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="formatObj.format_group"
                    mode="single"
                    placeholder="Grupa"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="formatGroups"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.format_group"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.format_group[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Rozmiar</label>
                <div class="col-sm-10 flex-inputs">
                  <div>
                    <input
                      v-model="formatObj.size_x"
                      name="size_x"
                      type="number"
                      class="form-control inputClass"
                      placeholder="Rozmiar X"
                      required="required"
                    />
                    <div v-if="errorsAdminData?.data?.size_x" class="has-error">
                      <span class="help-block">
                        {{ errorsAdminData.data.size_x[0] }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <input
                      v-model="formatObj.size_y"
                      name="size_y"
                      type="text"
                      class="form-control inputClass"
                      placeholder="Rozmiar Y"
                      required="required"
                    />
                    <div v-if="errorsAdminData?.data?.size_y" class="has-error">
                      <span class="help-block">
                        {{ errorsAdminData.data.size_y[0] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="formatObj.sheet_format"
                        name="sheet_format"
                        type="checkbox"
                      />
                      <span>Format arkusza</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/format/list">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "FormatCreate",
  mixins: [],
  components: {
    Multiselect,
  },
  props: {},

  setup() {
    const formatObj = ref({
      sheet_format: false,
    });
    const errorsAdminData = ref();
    const formatGroups = ref([]);
    const showErrors = ref(false);
    const store = useStore();
    const createFormatsData = computed(
      () => store.getters.getCreateFormatsData
    );
    const formatGroupsData = computed(
      () => store.getters.getFormatGroupsAdminData
    );

    const create = () => {
      showErrors.value = true;
      store.dispatch("createFormats", formatObj.value);
    };
    store.dispatch("getFormatGroupsAdmin");

    watch(
      () => createFormatsData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router
            .push({ name: "FormatsList", path: "/format/list" })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Nowy format został dodany",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    watch(
      () => formatGroupsData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          formatGroups.value.push({
            name: "[" + element.pk + "] " + element.name_pl,
            value: element.pk,
          });
        });
      }
    );

    return {
      formatObj,
      errorsAdminData,
      showErrors,
      create,
      formatGroups,
    };
  },
  computed: {},
};
</script>
