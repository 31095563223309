<template>
  <div class="row border-bottom">
    <nav class="navbar navbar-static-top white-bg" role="navigation">
      <div class="navbar-header">
        <span>
          <a
            class="navbar-minimalize minimalize-styl-2 btn btn-primary"
            href=""
          >
            <i class="fa fa-bars"></i>
          </a>
        </span>
      </div>
      <ul class="nav navbar-top-links navbar-right">
        <li>
          <a @click="logout()" class="logout-link">
            <i class="fa fa-sign-out"></i> Wyloguj
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
import router from "../../router/router";

export default {
  mixins: [],
  components: {},

  props: {},

  setup() {
    const { cookies } = useCookies();

    const logout = () => {
      cookies.set("CPsession", "");
      router.push({
        name: "Login",
        path: "/login",
      });
    };

    return {
      logout,
    };
  },
  computed: {},
};
</script>
