<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <p>
            <span v-html="content"></span>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" @click="modalReject()" class="btn btn-white">
            Nie
          </button>
          <button type="button" @click="modalConfirm()" class="btn btn-danger">
            Tak
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop"></div>
</template>

<script>
export default {
  name: "ModalPage",
  mixins: [],
  props: {
    title: String,
    content: String,
  },
  components: {},

  setup(props, context) {
    const modalReject = () => {
      context.emit("callbackClose");
    };
    const modalConfirm = () => {
      context.emit("callbackOk");
    };

    return {
      modalReject,
      modalConfirm,
    };
  },
};
</script>
