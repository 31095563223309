<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Kostki produkcyjne</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Kostki produkcyjne</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight logs">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-7 nopadding">
                <div class="form-group">
                  <label for="order_pk" class="control-label col-sm-2">
                    Numer zamówienia
                  </label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Numer zamówienia"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Kostki produkcyjne</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/montages/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="items" class="row">
              <router-link
                :to="'/cubes/details/' + item.pk"
                v-for="(item, index) in items"
                :key="index"
                class="col-sm-3 m-b-xs no-decoration"
              >
                <div class="widget lazur-bg p-xl">
                  <span class="badge badge-light">{{ item.site_name }}</span>
                  <b>
                    <h2>{{ index + 1 }}. {{ item.machine_name }}</h2>
                    <small></small>
                  </b>
                  <ul class="list-unstyled m-t-md">
                    <li>
                      <span>{{ item.machine_action_name }}</span>
                    </li>
                    <li>
                      <label>Liczba zleceń: </label>
                      <span>{{ item.products_count }}</span>
                    </li>
                    <li>
                      <span>{{ item.realization_date }}</span>
                    </li>
                  </ul>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "CubesList",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const store = useStore();
    const cubesData = computed(() => store.getters.getCubesData);

    const loading = ref(true);
    const mActions = ref(false);
    let items = ref([]);
    let itemsAll = ref(0);

    const getCubes = async () => {
      loading.value = true;
      await store.dispatch("getCubes");
    };

    getCubes();

    watch(
      () => cubesData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    return {
      items,
      itemsAll,
      loading,
      mActions,
    };
  },
  computed: {},
};
</script>
