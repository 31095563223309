<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Grupy pakowania</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Grupy pakowania</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Grupy pakowania</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/packages/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editPackage"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #expand>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Grupy pakowania</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="packageObj.name"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  W x H x L [cm]
                                </label>
                                <div class="col-sm-10 flex-inputs">
                                  <div>
                                    <input
                                      v-model="packageObj.width"
                                      name="width"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Szerokość"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.width"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.width[0] }}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <input
                                      v-model="packageObj.height"
                                      name="height"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Wysokość"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.height"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.height[0] }}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <input
                                      v-model="packageObj.length"
                                      name="length"
                                      type="text"
                                      class="form-control inputClass"
                                      placeholder="Długość"
                                      required="required"
                                    />
                                    <div
                                      v-if="errorsAdminData?.data?.length"
                                      class="has-error"
                                    >
                                      <span class="help-block">
                                        {{ errorsAdminData.data.length[0] }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Maksymalna waga [kg]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="packageObj.max_weight"
                                    name="max_weight"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Maksymalna waga [kg]"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.max_weight"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.max_weight[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="hr-line-dashed"></div>

                              <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">
                                  <button class="btn btn-white" @click="cancel">
                                    Anuluj
                                  </button>
                                  <button
                                    @click="saveEdit"
                                    class="btn btn-primary"
                                  >
                                    Zapisz
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #item-actions="item">
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.name;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deletePackage(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";

export default {
  name: "IpsList",
  mixins: [],
  components: {
    Modal,
  },
  props: {},

  setup() {
    const store = useStore();
    const deletePackagesData = computed(
      () => store.getters.getDeletePackagesData
    );
    const packageData = computed(() => store.getters.getPackageData);
    const updatePackagesData = computed(
      () => store.getters.getUpdatePackagesData
    );
    const packagesData = computed(() => store.getters.getPackagesData);
    const packageObj = ref({
      name: "",
      width: "",
      height: "",
      length: "",
      max_weight: "",
    });
    let headers = [
      { text: "Nr", value: "pk" },
      { text: "Nazwa", value: "name", sortable: true, search: true },
      {
        text: "Maksymalna waga [kg]",
        value: "max_weight",
        sortable: true,
        search: true,
      },
      { text: "W x H x L [cm]", value: "dimensions", search: true },
      {
        text: "Data dodania",
        value: "date_added",
        sortable: true,
        search: true,
      },
      {
        text: "Data modyfikacji",
        value: "date_modified",
        sortable: true,
        search: true,
      },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const getPackages = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getPackages", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const deletePackage = (pk) => {
      showModal.value = false;
      store.dispatch("deletePackages", {
        pk: pk,
      });
    };
    const saveEdit = () => {
      store.dispatch("updatePackages", packageObj.value);
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editPackage = (item) => {
      cancel();
      store.dispatch("getPackage", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getPackages();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getPackages();
    };

    const updateSort = () => {
      search.value = true;
    };

    getPackages();

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getPackages();
          }, 1000);
        }
      }
    );

    watch(
      () => packagesData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          element.dimensions =
            element.width + " x " + element.height + " x " + element.length;
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => packageData.value,
      function (newValue) {
        packageObj.value = newValue.data;
      }
    );

    watch(
      () => deletePackagesData.value,
      function (newValue) {
        console.log(newValue);
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Grupa została usunieta",
          });
        }
        getPackages();
      }
    );

    watch(
      () => updatePackagesData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getPackages();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getPackages();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      deletePackage,
      deleteName,
      packageObj,
      cancel,
      editPackage,
      saveEdit,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
    };
  },
  computed: {},
};
</script>
