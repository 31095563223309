<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Kompetencje</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Kompetencje</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Kompetencje</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/competencies/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editCompetence"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #expand>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Kompetencje {{ item }}</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label
                                  for="username"
                                  class="control-label col-sm-2"
                                >
                                  Nazwa
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    id="username"
                                    v-model="competenceObj.name_pl"
                                    name="username"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_pl[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label
                                  for="username"
                                  class="control-label col-sm-2"
                                >
                                  Nazwa [EN]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    id="username"
                                    v-model="competenceObj.name_en"
                                    name="username"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa [EN]"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_en"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_en[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label
                                  for="username"
                                  class="control-label col-sm-2"
                                >
                                  Nazwa [DE]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    id="username"
                                    v-model="competenceObj.name_de"
                                    name="username"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa [DE]"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_de"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_de[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label
                                  for="username"
                                  class="control-label col-sm-2"
                                >
                                  Nazwa [DE_DE]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    id="username"
                                    v-model="competenceObj.name_cpde"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa [DE_DE]"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_cpde"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_cpde[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label
                                  for="username"
                                  class="control-label col-sm-2"
                                >
                                  Nazwa [DE_EN]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    id="username"
                                    v-model="competenceObj.name_cpen"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa [DE_EN]"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_cpen"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_cpen[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Sklep
                                </label>
                                <div class="col-sm-10">
                                  <Multiselect
                                    v-model="competenceObj.country"
                                    mode="single"
                                    placeholder="Kraj"
                                    track-by="name"
                                    label="name"
                                    :close-on-select="false"
                                    :search="true"
                                    :options="countryList"
                                  >
                                  </Multiselect>
                                  <div
                                    v-if="
                                      !competenceObj.country.length &&
                                      showErrors
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      To pole nie może być puste
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-10">
                                  <div class="checkbox checkbox-primary">
                                    <label>
                                      <input
                                        v-model="competenceObj.reclamation"
                                        name="reclamation"
                                        type="checkbox"
                                      />
                                      <span>Reklamacja</span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-10">
                                  <div class="checkbox checkbox-primary">
                                    <label>
                                      <input
                                        v-model="competenceObj.complaint_reason"
                                        name="complaint_reason"
                                        type="checkbox"
                                        :checked="
                                          competenceObj.complaint_reason
                                        "
                                      />
                                      <span>Powód reklamacji</span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-10">
                                  <div class="checkbox checkbox-primary">
                                    <label>
                                      <input
                                        v-model="competenceObj.negotiations"
                                        id="negotiations"
                                        name="negotiations"
                                        type="checkbox"
                                        :checked="competenceObj.negotiations"
                                      />
                                      <span>Negocjacja</span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div class="hr-line-dashed"></div>

                              <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">
                                  <button @click="cancel" class="btn btn-white">
                                    Anuluj
                                  </button>
                                  <button
                                    @click="saveEdit"
                                    class="btn btn-primary"
                                  >
                                    Zapisz
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #item-reclamation="item">
                    <div v-if="item.reclamation">
                      <i class="fa fa-check"></i>
                    </div>
                    <div v-else>
                      <i class="fa fa-times"></i>
                    </div>
                  </template>
                  <template #item-complaint_reason="item">
                    <div v-if="item.complaint_reason">
                      <i class="fa fa-check"></i>
                    </div>
                    <div v-else>
                      <i class="fa fa-times"></i>
                    </div>
                  </template>
                  <template #item-negotiations="item">
                    <div v-if="item.negotiations">
                      <i class="fa fa-check"></i>
                    </div>
                    <div v-else>
                      <i class="fa fa-times"></i>
                    </div>
                  </template>
                  <template #item-actions="item">
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.name;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deleteCompetence(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "CompetenciesList",
  mixins: [],
  components: {
    Modal,
    Multiselect,
  },
  props: {},

  setup() {
    const store = useStore();
    const deleteCompetenceData = computed(
      () => store.getters.getDeleteCompetenceData
    );
    const competenceData = computed(() => store.getters.getCompetenceData);
    const updateCompetenceData = computed(
      () => store.getters.getUpdateCompetenceData
    );
    const competenciesData = computed(() => store.getters.getCompetenciesData);
    const groupObj = ref({
      name: "",
      permissions: [],
    });
    let headers = [
      { text: "Nr", value: "pk" },
      {
        text: "Nazwa",
        value: "name",
        sortable: true,
        search: true,
      },
      {
        text: "Sklep",
        value: "country_display",
        sortable: true,
        search: true,
      },
      {
        text: "Reklamacja",
        value: "reclamation",
        sortable: true,
      },
      { text: "Powód reklamacji", value: "complaint_reason", sortable: true },
      { text: "Negocjacja", value: "negotiations", sortable: true },
      { text: "Data dodania", value: "date_added", sortable: true },
      { text: "Data modyfikacji", value: "date_modified", sortable: true },
      {
        text: "Zmodyfikowano przez",
        value: "last_modified_by",
        sortable: true,
      },
      {
        text: "Utworzono przez",
        value: "created_by",
        sortable: true,
      },
      { text: "Akcje", value: "actions" },
    ];
    const countryList = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
    ]);

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const permissionsList = ref([]);
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    const competenceObj = ref({
      name_pl: "",
      name_en: "",
      name_de: "",
      name_cpen: "",
      name_cpde: "",
      country: "",
      reclamation: false,
      complaint_reason: false,
      negotiations: false,
    });
    const getCompetencies = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getCompetencies", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const deleteCompetence = (pk) => {
      showModal.value = false;
      store.dispatch("deleteCompetence", {
        pk: pk,
      });
    };
    const saveEdit = () => {
      store.dispatch("updateCompetence", competenceObj.value);
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editCompetence = (item) => {
      cancel();
      store.dispatch("getCompetence", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getCompetencies();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getCompetencies();
    };

    const updateSort = () => {
      search.value = true;
    };

    getCompetencies();

    watch(
      () => competenciesData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => competenceData.value,
      function (newValue) {
        competenceObj.value = newValue.data;
      }
    );

    watch(
      () => deleteCompetenceData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Kompetencja została usunięta",
          });
        }
        getCompetencies();
      }
    );

    watch(
      () => updateCompetenceData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getCompetencies();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getCompetencies();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getCompetencies();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      permissionsList,
      showModal,
      deleteCompetence,
      deleteName,
      groupObj,
      cancel,
      editCompetence,
      saveEdit,
      competenceObj,
      countryList,
      searchValue,
      isSearch,
      search,
      rowsPerPage,
      resetFilters,
      updateSort,
    };
  },
  computed: {},
};
</script>
