import { createApp } from "vue";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import BootstrapVue3 from "bootstrap-vue-3";
import App from "./App.vue";
import router from "./router/router";
import store from "./store";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import { Tabs, Tab } from "vue3-tabs-component";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/inspinia/css/style.css";
import "./assets/inspinia/css/animate.css";
import "./assets/scss/style.scss";
import { registerLicense } from "@syncfusion/ej2-base";

const vuetify = createVuetify({
  components,
  directives,
});

library.add(fas, far, fab);
dom.watch();
registerLicense(
  "ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Udk1jW35bcXNcTmdV"
);
createApp(App)
  .use(BootstrapVue3)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("EasyDataTable", Vue3EasyDataTable)
  .component("VueDatePicker", VueDatePicker)
  .component("tabs", Tabs)
  .component("tab", Tab)
  .component("QuillEditor", QuillEditor)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount("#app");
