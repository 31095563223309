<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Kategorie</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Kategorie</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Kategorie</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/categories/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items.length">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editCategories"
                  alternating
                  multi-sort
                  show-index
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #expand>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis kategorii [pl]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.title_pl"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_pl[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.title_seo_pl"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_seo_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_seo_pl[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Słowa kluczowe
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.keywords_seo_pl"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Słowa kluczowe"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data.keywords_seo_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.keywords_seo_pl[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Opis
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.description_seo_pl"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Opis"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data.description_seo_pl
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .description_seo_pl[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="categoryObj.description_pl"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.description_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_pl[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="ibox collapsed float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis kategorii [en]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.title_en"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_en"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_en[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.title_seo_en"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_seo_en"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_seo_en[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Słowa kluczowe
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.keywords_seo_en"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Słowa kluczowe"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.keywords_seo_en
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.keywords_seo_en[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Opis
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.description_seo_en"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Opis"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data.description_seo_en
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .description_seo_en[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="categoryObj.description_en"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.description_en"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_en[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="ibox collapsed float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis kategorii [de]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.title_de"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_de"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_de[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.title_seo_de"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_seo_de"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_seo_de[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Słowa kluczowe
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.keywords_seo_de"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Słowa kluczowe"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.keywords_seo_de
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.keywords_seo_de[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Opis
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.description_seo_de"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Opis"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data.description_seo_de
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .description_seo_de[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="categoryObj.description_de"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.description_de"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_de[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="ibox collapsed float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis kategorii [de_de]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.title_cpde"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_cpde"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_cpde[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.title_seo_cpde"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_seo_cpde"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.title_seo_cpde[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Słowa kluczowe
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.keywords_seo_cpde"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Słowa kluczowe"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data.keywords_seo_cpde
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .keywords_seo_cpde[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Opis
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.description_seo_cpde"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Opis"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data.description_seo_cpde
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .description_seo_cpde[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      categoryObj.description_cpde
                                    "
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.description_cpde
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_cpde[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="ibox collapsed float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis kategorii [de_en]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.title_cpen"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_cpen"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_cpen[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.title_seo_cpen"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_seo_cpen"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.title_seo_cpen[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Słowa kluczowe
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.keywords_seo_cpen"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Słowa kluczowe"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.keywords_seo_cpen
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .keywords_seo_cpen[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Opis
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="categoryObj.description_seo_cpen"
                                    name="name"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Opis"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data.description_seo_cpen
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .description_seo_cpen[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      categoryObj.description_cpen
                                    "
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.description_cpen
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.description_cpen[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Konfiguracja Pliku</h5>
                          </div>
                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Kategoria nadrzędna
                                </label>
                                <div class="col-sm-10">
                                  <Multiselect
                                    v-model="categoryObj.parent"
                                    mode="single"
                                    placeholder="Kategoria nadrzędna"
                                    track-by="name"
                                    label="name"
                                    :close-on-select="true"
                                    :search="true"
                                    :options="parents"
                                  >
                                  </Multiselect>
                                  <div
                                    v-if="categoryObj.parent && showErrors"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      To pole nie może być puste
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Dostępność w sklepach
                                </label>
                                <div class="col-sm-10">
                                  <Multiselect
                                    v-model="countries"
                                    mode="tags"
                                    placeholder="Dostępność  w sklepach"
                                    track-by="name"
                                    label="name"
                                    :close-on-select="false"
                                    :search="true"
                                    :options="countryList"
                                  >
                                  </Multiselect>
                                  <div
                                    v-if="
                                      !categoryObj.available_in_countries &&
                                      showErrors
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      To pole nie może być puste
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Obrazek
                                </label>
                                <div class="col-sm-2">
                                  <picture-input
                                    ref="pictureInput"
                                    width="300"
                                    height="300"
                                    margin="16"
                                    accept="image/jpeg,image/png"
                                    size="10"
                                    button-class="btn"
                                    :prefill="categoryObj?.image"
                                    :custom-strings="{
                                      upload: '<h1>Uploading!</h1>',
                                      drag: 'Drag & Drop',
                                    }"
                                    @change="onFileChange"
                                  >
                                  </picture-input>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-10">
                                  <div class="checkbox checkbox-primary">
                                    <label>
                                      <input
                                        v-model="categoryObj.is_published"
                                        id="active"
                                        name="active"
                                        type="checkbox"
                                      />
                                      <span>Aktywna</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">
                                  <button class="btn btn-white" @click="cancel">
                                    Anuluj
                                  </button>
                                  <button
                                    @click="saveEdit"
                                    class="btn btn-primary"
                                  >
                                    Zapisz
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #item-image="item">
                    <img v-if="item?.image" :src="item.image" width="40px" />
                  </template>
                  <template #item-is_published="item">
                    <div v-if="item" class="text-center">
                      <div v-if="!item.is_published">
                        <i class="fa fa-check"></i>
                      </div>
                      <div v-else>
                        <i class="fa fa-times"></i>
                      </div>
                    </div>
                  </template>
                  <template #item-actions="item">
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.title;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deleteCategories(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import PictureInput from "vue-picture-input";

export default {
  name: "CategoriesList",
  mixins: [],
  components: {
    Modal,
    Multiselect,
    PictureInput,
  },
  props: {},

  setup() {
    const store = useStore();
    const deleteCategoriesData = computed(
      () => store.getters.getDeleteCategoriesData
    );
    const categoryData = computed(() => store.getters.getCategoryData);
    const updateCategoriesData = computed(
      () => store.getters.getUpdateCategoriesData
    );
    const categoriesData = computed(() => store.getters.getCategoriesData);
    const categoriesList = computed(() => store.getters.getCategoryParentsData);

    let headers = [
      { text: "Obraz", value: "image", search: true, sortable: true },
      { text: "Nazwa", value: "title", search: true, sortable: true },
      {
        text: "Kategoria nadrzędna",
        value: "parent",
        search: true,
        sortable: true,
      },
      { text: "Aktywna", value: "is_published", sortable: true },
      { text: "Akcje", value: "actions" },
    ];
    const categoryObj = ref({
      name_pl: "",
      name_en: "",
      name_de: "",
      name_cpde: "",
      name_cpen: "",
      file: "",
      parent: [],
      is_published: false,
      available_in_countries: [],
    });

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const publication_date = ref();
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const parents = ref([]);
    const countries = ref([]);
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    const countryList = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
    ]);

    const getCategories = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getCategories", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const format = (date) => {
      let day, month, year, hours, minutes, seconds;
      day = date.getDate();
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      year = date.getFullYear();
      minutes = ("0" + date.getMinutes()).slice(-2);
      seconds = ("0" + date.getSeconds()).slice(-2);
      hours = date.getHours();

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    };

    const deleteCategories = (pk) => {
      showModal.value = false;
      store.dispatch("deleteFile", {
        pk: pk,
      });
    };
    const saveEdit = () => {
      if (typeof categoryObj.value.image == "string") {
        delete categoryObj.value.image;
      }
      categoryObj.value.available_in_countries = JSON.stringify(
        countries.value
      );
      delete categoryObj.value.subcategories;
      store.dispatch("updateCategories", categoryObj.value);
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editCategories = (item) => {
      cancel();
      store.dispatch("getCategory", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getCategories();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getCategories();
    };

    const updateSort = () => {
      search.value = true;
    };

    const onFileChange = (image) => {
      if (image.srcElement?.files[0]) {
        categoryObj.value.image = image.srcElement?.files[0];
      }
    };

    store.dispatch("getCategoryParents");

    getCategories();

    watch(
      () => categoriesData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => categoryData.value,
      function (newValue) {
        categoryObj.value = newValue.data;
        countries.value = categoryObj.value.available_in_countries;
      }
    );

    watch(
      () => deleteCategoriesData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Plik został usunięty",
          });
        }
        getCategories();
      }
    );

    watch(
      () => updateCategoriesData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getCategories();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getCategories();
          }, 1000);
        }
      }
    );

    watch(
      () => categoriesList.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          parents.value.push({
            name: "[" + element.pk + "] " + element.title,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getCategories();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      deleteCategories,
      deleteName,
      categoryObj,
      cancel,
      editCategories,
      saveEdit,
      publication_date,
      format,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
      onFileChange,
      countryList,
      parents,
      countries,
    };
  },
  computed: {},
};
</script>
