<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Procesy</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/processes">Procesy</router-link>
        </li>
        <li class="active">
          <strong>Nowa</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis procesu [pl]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Opis</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="processObj.description_pl"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis procesu [en]</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Opis</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="processObj.description_en"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis procesu [de]</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Opis</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="processObj.description_de"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis procesu [de_de]</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Opis</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="processObj.description_de_de"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_de_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_de_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis procesu [de_en]</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Opis</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="processObj.description_de_en"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_de_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_de_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/processes">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";

export default {
  name: "ProcessesCreate",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const processObj = ref({
      description_cpen: "",
      description_de: "",
      description_en: "",
      description_pl: "",
      description_cpde: "",
    });

    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const createProcessesData = computed(
      () => store.getters.getCreateProcessesData
    );

    const create = () => {
      showErrors.value = true;
      store.dispatch("createProcesses", processObj.value);
    };

    watch(
      () => createProcessesData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router
            .push({ name: "ProcessesList", path: "/processes" })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Nowa proces został dodany",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      processObj,
      errorsAdminData,
      showErrors,
      create,
    };
  },
  computed: {},
};
</script>
