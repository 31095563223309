<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Grupy rabatowe</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Grupy rabatowe</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Grupy rabatowe</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/discount-groups/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editDiscountGroup"
                  alternating
                  multi-sort
                  show-index
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #expand>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Grupy rabatowe</h5>
                          </div>
                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa [PL]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="dGroupObj.name_pl"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa [PL]"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_pl[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa [DE]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="dGroupObj.name_de"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa [DE]"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_de"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_de[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa [EN]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="dGroupObj.name_en"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa [EN]"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_en"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_en[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa [DE_DE]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="dGroupObj.name_cpde"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa [DE_DE]"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_cpde"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_cpde[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Nazwa [DE_EN]
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="dGroupObj.name_cpen"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Nazwa [DE_EN]"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.name_cpen"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.name_cpen[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="hr-line-dashed"></div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Obrót netto
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="dGroupObj.net_turnover"
                                    type="number"
                                    class="form-control inputClass"
                                    placeholder="Obrót netto"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.net_turnover"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.net_turnover[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Liczba zamówień
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="dGroupObj.orders_count"
                                    type="number"
                                    class="form-control inputClass"
                                    placeholder="Liczba zamówień"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.orders_count"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.orders_count[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Liczba dni
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="dGroupObj.days_count"
                                    type="number"
                                    class="form-control inputClass"
                                    placeholder="Liczba dni"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.days_count"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.days_count[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Procent
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="dGroupObj.value"
                                    type="number"
                                    class="form-control inputClass"
                                    placeholder="Procent"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.value"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.value[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="hr-line-dashed"></div>

                              <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">
                                  <button class="btn btn-white" @click="cancel">
                                    Anuluj
                                  </button>
                                  <button
                                    @click="saveEdit"
                                    class="btn btn-primary"
                                  >
                                    Zapisz
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #item-actions="item">
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.name;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deleteDiscountGroup(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";

export default {
  name: "DiscountGroupsList",
  mixins: [],
  components: {
    Modal,
  },
  props: {},

  setup() {
    const store = useStore();
    const deleteDiscountGroupData = computed(
      () => store.getters.getDeleteDiscountGroupData
    );
    const DiscountGroupData = computed(
      () => store.getters.getDiscountGroupData
    );
    const updateDiscountGroupData = computed(
      () => store.getters.getUpdateDiscountGroupData
    );
    const discountGroupsData = computed(
      () => store.getters.getDiscountGroupsData
    );
    let headers = [
      { text: "ID", value: "pk" },
      {
        text: "Nazwa",
        value: "name",
        sortable: true,
        search: true,
      },
      {
        text: "Obrót netto",
        value: "net_turnover",
        sortable: true,
        search: true,
      },
      {
        text: "Liczba zamówień",
        value: "orders_count",
        sortable: true,
      },
      { text: "Liczba dni", value: "days_count", sortable: true },
      { text: "Procent", value: "value", sortable: true },
      { text: "Data dodania", value: "date_added", sortable: true },
      { text: "Data modyfikacji", value: "date_modified", sortable: true },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const permissionsList = ref([]);
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    const dGroupObj = ref({});
    const getDiscountGroups = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getDiscountGroups", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const deleteDiscountGroup = (pk) => {
      showModal.value = false;
      store.dispatch("deleteDiscountGroup", {
        pk: pk,
      });
    };
    const saveEdit = () => {
      store.dispatch("updateDiscountGroup", dGroupObj.value);
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editDiscountGroup = (item) => {
      cancel();
      store.dispatch("getDiscountGroup", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getDiscountGroups();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getDiscountGroups();
    };

    const updateSort = () => {
      search.value = true;
    };

    getDiscountGroups();

    watch(
      () => discountGroupsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => DiscountGroupData.value,
      function (newValue) {
        dGroupObj.value = newValue.data;
      }
    );

    watch(
      () => deleteDiscountGroupData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Grupa rabatów została usunięta",
          });
        }
        getDiscountGroups();
      }
    );

    watch(
      () => updateDiscountGroupData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getDiscountGroups();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getDiscountGroups();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getDiscountGroups();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      permissionsList,
      showModal,
      deleteDiscountGroup,
      deleteName,
      cancel,
      editDiscountGroup,
      saveEdit,
      dGroupObj,
      searchValue,
      isSearch,
      search,
      rowsPerPage,
      resetFilters,
      updateSort,
    };
  },
  computed: {},
};
</script>
