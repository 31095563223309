<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Logi systemowe</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Logi systemowe</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Logi systemowe</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SystemlogsList",
  mixins: [],
  components: {},
  props: {},

  setup() {
    return {};
  },
  computed: {},
};
</script>
