<template>
  <div v-if="barcodeData" class="barcode-wrapper">
    <span class="barcode-username">{{ barcodeData.data.fullName }}</span>
    <div v-html="barcodeData.data.barcode"></div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "AdminBarcode",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const store = useStore();
    const route = useRoute();
    const barcodeData = computed(() => store.getters.getAdminBarcodeData);
    document.body.classList.add("barcode-view");
    if (route.params.id) {
      store.dispatch("getAdminBarcode", {
        pk: route.params.id,
      });
    }
    return {
      barcodeData,
    };
  },
  computed: {},
  unmounted() {
    document.body.classList.remove("barcode-view");
  },
};
</script>
