<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Aktualności</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/news/list">Aktualności</router-link>
        </li>
        <li class="active">
          <strong>Nowa</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis aktualności [pl]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Tytuł
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="newsObj.title_pl"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.title_pl" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.title_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="newsObj.content_pl"
                  />
                  <div v-if="errorsAdminData?.data?.title_pl" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.title_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis aktualności [en]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Tytuł
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="newsObj.title_en"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Tytuł"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.title_en" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.title_en[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="newsObj.content_en"
                  />
                  <div
                    v-if="errorsAdminData?.data?.content_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.content_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis aktualności [de]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Tytuł
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="newsObj.title_de"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.title_de" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.title_de[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="newsObj.content_de"
                  />
                  <div
                    v-if="errorsAdminData?.data?.content_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.content_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis aktualności [de_de]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Tytuł
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="newsObj.title_cpde"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="newsObj.content_cpde"
                  />
                  <div
                    v-if="errorsAdminData?.data?.content_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.content_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis aktualności [de_en]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Tytuł
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="newsObj.title_cpen"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="newsObj.content_cpen"
                  />
                  <div
                    v-if="errorsAdminData?.data?.content_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.content_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Konfiguracja aktualności</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Data publikacji</label>
                <div class="col-sm-10">
                  <VueDatePicker :format="format" v-model="publication_date" />
                  <div
                    v-if="errorsAdminData?.data?.publication_date"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.publication_date[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/news/list">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";

export default {
  name: "NewsCreate",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const newsObj = ref({
      title_pl: "",
      content_pl: "",
      title_en: "",
      content_en: "",
      title_de: "",
      content_de: "",
      title_cpde: "",
      content_cpde: "",
      title_cpen: "",
      content_cpen: "",
      publication_date: "",
    });
    const publication_date = ref();
    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const createNewsData = computed(() => store.getters.getCreateNewsData);

    const create = () => {
      showErrors.value = true;
      newsObj.value.publication_date = format(publication_date.value);
      store.dispatch("createNews", newsObj.value);
    };

    const format = (date) => {
      let day, month, year, hours, minutes, seconds;
      day = date.getDate();
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      year = date.getFullYear();
      minutes = ("0" + date.getMinutes()).slice(-2);
      seconds = ("0" + date.getSeconds()).slice(-2);
      hours = date.getHours();

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    };

    watch(
      () => createNewsData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router
            .push({
              name: "NewsList",
              path: "/news/list",
            })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Nowy news został dodany",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      newsObj,
      errorsAdminData,
      showErrors,
      create,
      format,
      publication_date,
    };
  },
  computed: {},
};
</script>
