<template>
  <div class="footer">
    <div class="pull-right">
      Lorem
      <strong>ipsum</strong>
      Dolor.
    </div>
    <div>
      <strong>Copyright</strong>
      Caffeprint &copy; 2015-2023
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterPage",
  mixins: [],
  components: {},

  props: {},

  setup() {},
};
</script>
