<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Grupy formatów</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/format-groups">Grupy formatów</router-link>
        </li>
        <li class="active">
          <strong>Nowa</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Nazwa grupy</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa [pl]</label>
                <div class="col-sm-10">
                  <input
                    v-model="formatGroupObj.name_pl"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [pl]"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_pl" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa [de]</label>
                <div class="col-sm-10">
                  <input
                    v-model="formatGroupObj.name_de"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [de]"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_de" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa [en]</label>
                <div class="col-sm-10">
                  <input
                    v-model="formatGroupObj.name_en"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [en]"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_de" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa [de_de]</label>
                <div class="col-sm-10">
                  <input
                    v-model="formatGroupObj.name_cpde"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [de_de]"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.name_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.name_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa [de_en]</label>
                <div class="col-sm-10">
                  <input
                    v-model="formatGroupObj.name_cpen"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [de_en]"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.name_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.name_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/format-groups">
                    Anuluj
                  </router-link>
                  <button @click="saveEdit" class="btn btn-primary">
                    Zapisz
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import { useRoute } from "vue-router";

export default {
  name: "FormatGroupsCreate",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const formatGroupObj = ref({});
    const errorsAdminData = ref();
    const showErrors = ref(false);
    const showModalErrors = ref(false);
    const store = useStore();
    const formatGroupData = computed(() => store.getters.getFormatGroupData);
    const updateFormatGroupsData = computed(
      () => store.getters.getUpdateFormatGroupsData
    );

    const route = useRoute();
    store.dispatch("getFormatGroup", { pk: route.params.pk });

    const saveEdit = () => {
      showErrors.value = true;
      store.dispatch("updateFormatGroups", formatGroupObj.value);
    };

    watch(
      () => formatGroupData.value,
      function (newValue) {
        formatGroupObj.value = newValue.data;
      }
    );

    watch(
      () => updateFormatGroupsData.value,
      function (newValue) {
        if (newValue.status === 200) {
          router
            .push({ name: "FormatGroupsList", path: "/format-groups" })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Dane zostały zaktualizowane",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      formatGroupObj,
      errorsAdminData,
      showErrors,
      saveEdit,
      showModalErrors,
    };
  },
  computed: {},
};
</script>
