<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Grupy formatów</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Grupy formatów</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Grupy formatów</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/format-groups/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="false"
                  @click-row="formatGroupsDetail"
                  :loading="loading"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #item-actions="item">
                    <router-link :to="'/format-groups/edit/' + item.pk">
                      <button class="btn btn-warning btn-xs">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </router-link>
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.name_pl;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                  <template #item-image="item">
                    <img :src="item.image" />
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deleteFormatGroups(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";

export default {
  name: "FormatGroupsList",
  mixins: [],
  components: {
    Modal,
  },
  props: {},

  setup() {
    const store = useStore();
    const deleteFormatGroupsData = computed(
      () => store.getters.getDeleteFormatGroupsData
    );
    const FormatGroupsData = computed(() => store.getters.getFormatGroupsData);
    const formatGroupObj = ref({
      name: "",
      model: "",
      type: "",
      image: "",
    });
    let headers = [
      { text: "ID", value: "pk" },
      {
        text: "Nazwa",
        value: "name_pl",
        sortable: true,
        search: true,
      },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const getFormatGroups = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getFormatGroups", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const deleteFormatGroups = (pk) => {
      showModal.value = false;
      store.dispatch("deleteFormatGroups", {
        pk: pk,
      });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getFormatGroups();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getFormatGroups();
    };

    const updateSort = () => {
      search.value = true;
    };

    const formatGroupsDetail = (item) => {
      console.log(item);
    };

    getFormatGroups();

    watch(
      () => FormatGroupsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => deleteFormatGroupsData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Parametr został usunięty",
          });
        }
        getFormatGroups();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getFormatGroups();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getFormatGroups();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      deleteFormatGroups,
      deleteName,
      formatGroupObj,
      searchValue,
      isSearch,
      rowsPerPage,
      resetFilters,
      updateSort,
      search,
      formatGroupsDetail,
    };
  },
  computed: {},
};
</script>
