<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Pakowanie</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/order/send">Wysyłka zamówień</router-link>
        </li>
        <li class="active">
          <strong>Pakowanie</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <button @click="getAdminCard" class="btn btn-primary">
          <i class="fa fa-file"></i>
          Pobierz kartę zamówienia
        </button>
        <div
          v-for="(dData, index) in deliveryData?.delivery_addresses"
          class="ibox float-e-margins"
          :key="index"
        >
          <div class="ibox-title">
            <h5>Adres dostawy nr. {{ index }}</h5>
            |
            <a @click="generateLabel(dData.pk)">Wygeneruj listy przewozowe</a>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="row">
                <div
                  v-for="(adress, index1) in dData?.delivery_packages"
                  :key="index1"
                  class="col-lg-4"
                >
                  <h3>{{ adress.package.name }}</h3>
                  <p class="small">
                    <i class="fa fa-truck"></i>
                    <span>{{ adress.package.height }}</span>
                    <i class="fa fa-times"></i>
                    <span>{{ adress.package.width }}</span>
                    <i class="fa fa-times"></i>
                    <span>{{ adress.package.length }}</span>
                    <span>[cm]</span>
                  </p>
                  <ul class="sortable-list agile-list">
                    <li
                      v-for="(product, index2) in adress?.products"
                      :key="index2"
                      class="success-element"
                    >
                      <span class="label label-primary">
                        {{ product.product_pk }}
                      </span>
                      <span class="ng-binding">{{ product.product_name }}</span>
                    </li>
                  </ul>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="ibox float-e-margins">
                      <div class="ibox-title">
                        <h5>Zawartość adresu</h5>
                        <div v-if="dData?.products" class="ibox-content">
                          <EasyDataTable
                            :headers="headers"
                            :items="dData.products"
                            show-index
                            :clickRowToExpand="false"
                            :loading="loading"
                            alternating
                            multi-sort
                            border-cell
                            class="table-search"
                          >
                            <template #header="header">
                              <span v-if="header.text">{{ header.text }}</span>
                              <input
                                class="table-search"
                                type="text"
                                v-if="header.search"
                                v-model="searchValue[header.value]"
                                @keyup="search"
                                @click="isSearch = true"
                              />
                            </template>
                            <template #item-actions="item">
                              <button
                                @click="
                                  getOrderProductFiles(
                                    item.product_file.pk,
                                    '/get_client_file/'
                                  )
                                "
                                class="btn btn-xs"
                                title="Plik Klienta"
                              >
                                <i class="fa fa-user"></i>
                              </button>
                              <button
                                @click="
                                  getOrderProductFiles(
                                    item.product_file.pk,
                                    '/get_result_file/'
                                  )
                                "
                                class="btn btn-xs"
                                title="Plik wyjściowy"
                              >
                                <i class="fa fa-search"></i>
                              </button>
                              <button
                                @click="
                                  getOrderProductFiles(
                                    item.product_file.pk,
                                    '/get_client_cover_file/'
                                  )
                                "
                                class="btn btn-xs"
                                title="Plik okładki klienta"
                              >
                                <i class="fa fa-user"></i>
                              </button>
                              <button
                                @click="
                                  getOrderProductFiles(
                                    item.product_file.pk,
                                    '/get_cover_result_file/'
                                  )
                                "
                                class="btn btn-xs"
                                title="Plik okładki wyjściowy"
                              >
                                <i class="fa fa-search"></i>
                              </button>
                            </template>
                            <template #item-preview="item">
                              <img
                                :src="item.product_file.client_file_thumbnail"
                                width="40px"
                              />
                            </template>
                            <template #item-weight="item">
                              <input
                                v-model="weight[item.pk]"
                                @blur="handleWeight(item.pk)"
                                type="text"
                                class="form-control inputClass"
                                placeholder="Waga"
                                required="required"
                              />
                              {{ item.weight }}
                            </template>
                          </EasyDataTable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "OrderPacking",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const orderId = ref();
    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const labelData = computed(() => store.getters.getLabelData);
    const realWeightData = computed(() => store.getters.getRealWeightData);
    const adminSendUpsData = computed(() => store.getters.getAdminSendUpsData);
    const orderProductFilesData = computed(
      () => store.getters.getOrderProductFilesData
    );
    const route = useRoute();
    const deliveryData = ref();
    const weight = ref([]);
    let headers = [
      { text: "Podgląd", value: "preview" },
      { text: "ID zlecenia", value: "order_product" },
      { text: "Produkt", value: "product_name" },
      { text: "Nakład", value: "amount" },
      { text: "Waga", value: "weight" },
      { text: "Akcje", value: "actions" },
    ];

    const getAdminCard = () => {
      if (route.params?.pk) {
        store.dispatch("getOrderAdminCard", route.params?.pk);
      }
    };

    const generateLabel = (pk) => {
      if (pk) {
        store.dispatch("generateLabel", pk);
      }
    };

    const handleWeight = (pk) => {
      if (pk && weight.value) {
        store.dispatch("upsUpdateRealWeight", {
          pk: pk,
          real_weight: weight.value[pk],
        });
      }
    };

    const getOrderProductFiles = (pk, type) => {
      if (pk && weight.value) {
        store.dispatch("getOrderProductFiles", {
          pk: pk,
          type: type,
        });
      }
    };

    if (route.params?.pk) {
      store.dispatch("adminSendUpsRetrieve", route.params?.pk);
    }

    watch(
      () => labelData.value,
      function (newValue) {
        if (newValue.status === 200) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Redirect...",
          });
        } else {
          var msg = [];
          for (const value of Object.entries(newValue.data)) {
            msg.push(value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    watch(
      () => adminSendUpsData.value,
      function (newValue) {
        newValue.data.delivery_addresses?.forEach((element) => {
          element.products?.forEach((elem) => {
            weight.value[elem.pk] = elem.real_weight;
          });
        });
        deliveryData.value = newValue.data;
      }
    );

    watch(
      () => realWeightData.value,
      function (newValue) {
        if (newValue.status === 200) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Waga produktu została pomyślnie zapisana.",
          });
        } else {
          var msg = [];
          for (const value of Object.entries(newValue.data)) {
            msg.push(value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );
    watch(
      () => orderProductFilesData.value,
      function (newValue) {
        if (newValue.status !== 200) {
          store.dispatch("showToaster", {
            type: "error",
            content: newValue.response.statusText,
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      errorsAdminData,
      showErrors,
      orderId,
      getAdminCard,
      deliveryData,
      headers,
      generateLabel,
      handleWeight,
      weight,
      getOrderProductFiles,
    };
  },
  computed: {},
};
</script>
