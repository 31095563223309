<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Użytkownicy</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Użytkownicy</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Użytkownicy</h5>
            <span class="online-box">
              Online: {{ usersOnline.count }}
              <div class="online-users">
                <span v-for="(user, key) in usersOnline.results" :key="key">
                  {{ user.user.first_name }} {{ user.user.last_name }}
                </span>
              </div>
            </span>
          </div>
          <div class="ibox-content">
            <router-link to="admins/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group group-options">
                  <label class="control-label col-sm-2">
                    Zaznaczone elementy:
                  </label>
                  <div class="col-sm-2 buttons">
                    <Multiselect
                      mode="single"
                      v-model="groupActions"
                      track-by="name"
                      label="name"
                      placeholder="Wybierz"
                      :close-on-select="true"
                      :search="true"
                      :options="[
                        { value: 'activate_user', name: 'Aktywuj' },
                        { value: 'deactivate_user', name: 'Dezktywuj' },
                      ]"
                    />
                    <button @click="groupAction" class="btn btn-sm btn-primary">
                      Wykonaj
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  v-model:items-selected="itemsSelected"
                  :body-row-class-name="bodyRowClassNameFunction"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editAdmin"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #expand="item">
                    <tabs :options="{ disableScrollBehavior: true }">
                      <tab name="Edycja">
                        <div
                          class="wrapper wrapper-content animated fadeInRight"
                          v-if="adminObj.user.username"
                        >
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="ibox float-e-margins">
                                <div class="ibox-title">
                                  <h5>
                                    Edycja użytkownika {{ item.username }}
                                  </h5>
                                </div>

                                <div class="form-horizontal">
                                  <div class="ibox-content">
                                    <div class="form-group">
                                      <label
                                        for="username"
                                        class="control-label col-sm-2"
                                      >
                                        Nazwa użytkownika
                                      </label>
                                      <div class="col-sm-10">
                                        <input
                                          id="username"
                                          name="username"
                                          v-model="adminObj.user.username"
                                          type="text"
                                          class="form-control inputClass"
                                          placeholder="Nazwa użytkownika"
                                          required="required"
                                        />
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label
                                        for="password"
                                        class="control-label col-sm-2"
                                      >
                                        Hasło
                                      </label>
                                      <div class="col-sm-10">
                                        <input
                                          id="password"
                                          type="password"
                                          v-model="adminObj.user.password"
                                          class="form-control inputClass"
                                          placeholder="Haslo"
                                          required="required"
                                        />
                                      </div>
                                    </div>

                                    <div class="hr-line-dashed"></div>

                                    <div class="form-group">
                                      <label
                                        for="email"
                                        class="control-label col-sm-2"
                                      >
                                        Adres e-mail
                                      </label>
                                      <div class="col-sm-10">
                                        <input
                                          id="email"
                                          type="email"
                                          class="form-control inputClass"
                                          placeholder="Adres e-mail"
                                          v-model="adminObj.user.email"
                                          required="required"
                                        />
                                        <div
                                          v-if="
                                            errorsAdminData?.data?.user?.email
                                          "
                                          class="has-error"
                                        >
                                          <span class="help-block">
                                            {{
                                              errorsAdminData.data.user.email[0]
                                            }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="form-group">
                                      <label
                                        for="name"
                                        class="control-label col-sm-2"
                                      >
                                        Imię
                                      </label>
                                      <div class="col-sm-10">
                                        <input
                                          id="name"
                                          type="text"
                                          class="form-control inputClass"
                                          v-model="adminObj.user.first_name"
                                          placeholder="Imię"
                                          required="required"
                                        />
                                        <div
                                          v-if="
                                            errorsAdminData?.data?.user
                                              ?.first_name
                                          "
                                          class="has-error"
                                        >
                                          <span class="help-block">
                                            {{
                                              errorsAdminData.data.user
                                                .first_name[0]
                                            }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="form-group">
                                      <label
                                        for="lastname"
                                        class="control-label col-sm-2"
                                      >
                                        Nazwisko
                                      </label>
                                      <div class="col-sm-10">
                                        <input
                                          id="lastname"
                                          type="text"
                                          v-model="adminObj.user.last_name"
                                          class="form-control inputClass"
                                          placeholder="Nazwisko"
                                          required="required"
                                        />
                                        <div
                                          v-if="
                                            errorsAdminData?.data?.user
                                              ?.lastname
                                          "
                                          class="has-error"
                                        >
                                          <span class="help-block">
                                            {{
                                              errorsAdminData?.data.user
                                                .lastname[0]
                                            }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="form-group">
                                      <label lass="control-label col-sm-2">
                                        Zdjęcie
                                      </label>
                                      <div class="col-sm-10">
                                        <picture-input
                                          ref="pictureInput"
                                          width="300"
                                          height="300"
                                          margin="16"
                                          accept="image/jpeg,image/png"
                                          button-class="btn"
                                          :prefill="adminObj?.image"
                                          :custom-strings="{
                                            upload: '<h1>Uploading!</h1>',
                                            drag: 'Drag & Drop',
                                          }"
                                          @change="changePhoto"
                                        >
                                        </picture-input>
                                      </div>
                                    </div>

                                    <div class="hr-line-dashed"></div>

                                    <div class="form-group">
                                      <label class="control-label col-sm-2">
                                        Czynności maszyn
                                      </label>
                                      <div class="col-sm-10">
                                        <Multiselect
                                          mode="tags"
                                          v-model="adminObj.machine_actions"
                                          placeholder="Czynności maszyn"
                                          track-by="name"
                                          label="name"
                                          :close-on-select="false"
                                          :search="true"
                                          :options="machineActionsList"
                                        >
                                        </Multiselect>
                                        <!-- <divs
                                          v-if="
                                            !adminObj.machine_actions.length &&
                                            showErrors
                                          "
                                          class="has-error"
                                        >
                                          <span class="help-block">
                                            To pole nie może być puste
                                          </span>
                                        </div> -->
                                      </div>
                                    </div>

                                    <div class="form-group">
                                      <label class="control-label col-sm-2">
                                        Kompetencje
                                      </label>
                                      <div class="col-sm-10">
                                        <Multiselect
                                          mode="tags"
                                          placeholder="Kompetencje"
                                          v-model="adminObj.competencies"
                                          track-by="name"
                                          label="name"
                                          :close-on-select="false"
                                          :search="true"
                                          :options="competenciesList"
                                        >
                                        </Multiselect>
                                        <div
                                          v-if="
                                            !adminObj?.competencies.length &&
                                            showErrors
                                          "
                                          class="has-error"
                                        >
                                          <span class="help-block">
                                            To pole nie może być puste
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="form-group">
                                      <label class="control-label col-sm-2">
                                        Grupy uprawnień
                                      </label>
                                      <div class="col-sm-10">
                                        <Multiselect
                                          mode="tags"
                                          placeholder="Grupy uprawnień"
                                          v-model="adminObj.user.groups"
                                          track-by="name"
                                          label="name"
                                          :close-on-select="false"
                                          :search="true"
                                          :options="permissionsList"
                                        >
                                        </Multiselect>
                                      </div>
                                    </div>
                                    <div class="hr-line-dashed"></div>
                                    <div class="form-group">
                                      <div class="col-sm-4 col-sm-offset-2">
                                        <button
                                          @click="cancel"
                                          class="btn btn-white"
                                        >
                                          Anuluj
                                        </button>
                                        <button
                                          @click="saveEdit"
                                          class="btn btn-primary"
                                        >
                                          Zapisz
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </tab>
                      <tab name="Statystyka">
                        <VueDatePicker
                          class="stats-daterangepicker"
                          v-model="statsRangeDate"
                          multi-calendars
                          :format="format"
                          range
                        />
                        <EasyDataTable
                          id="stats"
                          :headers="statsHeaders"
                          :items="statsItems"
                          :loading="loading"
                          class="stats-table"
                          alternating
                          multi-sort
                        />
                      </tab>
                    </tabs>
                  </template>
                  <template #item-user_card="item">
                    <router-link
                      class="btn btn-primary btn-xs"
                      :to="'/admins/' + item.pk + '/barcode'"
                    >
                      <i class="fa fa-id-card"></i>
                    </router-link>
                  </template>
                  <template #item-actions="item">
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.username;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć ' + deleteName + ' ?'"
    @callbackOk="deleteUser(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Modal from "../caffeprint/Modal.vue";
import PictureInput from "vue-picture-input";
import { useCookies } from "vue3-cookies";
import { useRoute } from "vue-router";

export default {
  name: "AdminsList",
  mixins: [],
  components: {
    Multiselect,
    Modal,
    PictureInput,
  },
  props: {},

  setup() {
    const store = useStore();
    store.dispatch("getMachineActions");
    store.dispatch("getCompetencies");
    store.dispatch("getPermissionsGroup");
    const adminsData = computed(() => store.getters.getAdminsData);
    const onlineAdminsData = computed(() => store.getters.getOnlineAdminsData);
    const machineActionsData = computed(
      () => store.getters.getMachineActionsData
    );
    const competenciesData = computed(() => store.getters.getCompetenciesData);
    const adminEditData = computed(() => store.getters.getAdminEditData);
    const adminGroupEditData = computed(
      () => store.getters.getAdminGroupEditData
    );
    const adminEditionData = computed(() => store.getters.getAdminEditionData);
    const adminDeleteData = computed(() => store.getters.getAdminDeleteData);
    const adminLogsData = computed(() => store.getters.getAdminLogsData);
    const permissionsGroupData = computed(
      () => store.getters.getPermissionsGroupData
    );
    const { cookies } = useCookies();
    const WEBSOCKET_URL = `wss://staging.backend.caffeprint.eu/ws/online-users/`;
    var websocket = new WebSocket(
      WEBSOCKET_URL + cookies.get("CPsession") + "/"
    );
    const route = useRoute();

    websocket.onmessage = function (event) {
      var data = JSON.parse(event.data),
        pks = "";
      data.connected_user_pks.forEach((element) => {
        if (element) {
          pks += element + ",";
        }
      });

      store.dispatch("getOnlineAdmins", pks);
    };

    const adminObj = ref({
      user: {
        username: "",
        password: "",
        email: "",
        first_name: "",
        last_name: "",
        groups: [],
      },
      competencies: [],
      machine_actions: [],
      image: [],
    });
    let headers = [
      { text: "Nr", value: "pk" },
      {
        text: "Login",
        value: "username",
        search: true,
      },
      {
        text: "Imię",
        value: "first_name",
        sortable: true,
        search: true,
      },
      {
        text: "Nazwisko",
        value: "last_name",
        sortable: true,
        search: true,
      },
      {
        text: "Adres e-mail",
        value: "email",
        sortable: true,
        search: true,
      },
      {
        text: "Data dodania",
        value: "date_added",
        sortable: true,
        search: true,
      },
      {
        text: "Data modyfikacji",
        value: "date_modified",
        sortable: true,
        search: true,
      },
      {
        text: "Data ostatniego logowania",
        value: "auth_token.created",
        sortable: true,
        search: true,
      },
      {
        text: "Edytowano przez",
        value: "last_modified_by",
        sortable: true,
        search: true,
      },
      { text: "Karta użytkownika", value: "user_card" },
      { text: "Akcje", value: "actions" },
    ];

    let statsHeaders = [
      { text: "Nr", value: "pk" },
      { text: "Data zdarzenia", value: "created", sortable: true },
      { text: "Użytkownik", value: "name", sortable: true },
      {
        text: "Zmieniony proces",
        value: "changed_object_name",
        sortable: true,
      },
      { text: "NR Zamówienia", value: "order_id", sortable: true },
      {
        text: "ID pozycji zamówienia",
        value: "order_product_id",
        sortable: true,
      },
      { text: "Nr Montażu", value: "montage_nr", sortable: true },
      { text: "Status przed zmianą", value: "old_values", sortable: true },
      { text: "Status po zmianie", value: "new_values", sortable: true },
      { text: "Data rozpoczęcia", value: "start_date", sortable: true },
      { text: "Data zakończenia", value: "end_date", sortable: true },
      { text: "Czas", value: "time", sortable: true },
    ];
    let items = ref([]);
    let statsItems = ref([]);
    let usersOnline = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const itemsSelected = ref([]);
    const machineActionsList = ref([]);
    const competenciesList = ref([]);
    const permissionsList = ref([]);
    const loading = ref(true);
    const statsRangeDate = ref();
    const statsEditedPk = ref();
    const groupActions = ref();
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 5,
    });

    const getAdmins = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-user__" + serverOptions.value.sortBy;
      } else {
        sort = "user__" + serverOptions.value.sortBy;
      }
      await store.dispatch("getAdmins", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
        userPk: route.params?.pk,
      });
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const saveEdit = () => {
      if (typeof adminObj.value.image === "string") {
        delete adminObj.value.image;
      }
      store.dispatch("updateAdmin", adminObj.value);
    };

    const groupAction = () => {
      let pks = [];
      itemsSelected.value.forEach((element) => {
        pks.push(element.pk);
      });
      store.dispatch("updateAdminsGroup", {
        pks: pks,
        action: groupActions.value,
      });
    };

    const editAdmin = (item) => {
      cancel();
      statsEditedPk.value = item.pk;
      store.dispatch("getAdmin", { pk: item.pk });
      store.dispatch("getAdminProductionLogs", { pk: item.pk });
    };

    const deleteUser = (pk) => {
      showModal.value = false;
      store.dispatch("deleteAdmin", {
        pk: pk,
        userObj: {
          user: {
            is_active: false,
          },
        },
      });
    };

    const changePhoto = (image) => {
      if (image.srcElement?.files[0]) {
        adminObj.value.image = image.srcElement?.files[0];
      }
    };

    const bodyRowClassNameFunction = (item) => {
      if (item.is_online) {
        return "online-row";
      }

      return false;
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getAdmins();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getAdmins();
    };

    const updateSort = () => {
      search.value = true;
    };

    const format = (date) => {
      let day, month, year, dayEnd, monthEnd, yearEnd;
      if (date[0]) {
        day = date[0].getDate();
        month = date[0].getMonth() + 1;
        year = date[0].getFullYear();
      }

      if (date[1]) {
        dayEnd = date[1].getDate();
        monthEnd = date[1].getMonth() + 1;
        yearEnd = date[1].getFullYear();
      }

      if (date.length > 1) {
        return `${day}/${month}/${year} - ${dayEnd}/${monthEnd}/${yearEnd}`;
      }
    };

    getAdmins();

    watch(
      () => adminsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          element.user.pk = element.pk;
          items.value.push(element.user);
        });
        loading.value = false;
      }
    );
    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getAdmins();
          isSearch.value = false;
        }
      }
    );

    watch(
      () => machineActionsData.value,
      function (newValue) {
        console.log(newValue);
        newValue.data?.forEach((element) => {
          machineActionsList.value.push({
            name: "[" + element.pk + "] " + element.name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => competenciesData.value,
      function (newValue) {
        newValue.data.results?.forEach((element) => {
          competenciesList.value.push({
            name: "[" + element.pk + "] " + element.name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => permissionsGroupData.value,
      function (newValue) {
        newValue.data.results?.forEach((element) => {
          permissionsList.value.push({
            name: "[" + element.pk + "] " + element.name,
            value: element.pk,
          });
        });
      }
    );

    watch(
      () => adminEditData.value,
      function (newValue) {
        adminObj.value = newValue.data;
      }
    );

    watch(
      () => adminGroupEditData.value,
      function (newValue) {
        if (newValue.status === 200) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }
      }
    );

    watch(
      () => adminEditionData.value,
      function (newValue) {
        if (newValue.status === 200) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }
        cancel();
      }
    );

    watch(
      () => adminDeleteData.value,
      function (newValue) {
        if (newValue.status === 200) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Użytkownik zostal usunięty",
          });
        }
        getAdmins();
      }
    );

    watch(
      () => adminLogsData.value,
      function (newValue) {
        statsItems.value = [];
        newValue.data.results?.forEach((element) => {
          statsItems.value.push(element);
        });
      }
    );

    watch(
      () => onlineAdminsData.value,
      function (newValue) {
        usersOnline.value = newValue.data;
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getAdmins();
          }, 1000);
        }
      }
    );

    watch(
      () => statsRangeDate.value,
      function (newValue) {
        let day, month, year, dayEnd, monthEnd, yearEnd, params;
        if (newValue && newValue[0]) {
          day = newValue[0].getDate();
          month = newValue[0].getMonth() + 1;
          year = newValue[0].getFullYear();
        }

        if (newValue && newValue[1]) {
          dayEnd = newValue[1].getDate();
          monthEnd = newValue[1].getMonth() + 1;
          yearEnd = newValue[1].getFullYear();
          params =
            "&min_date=" +
            day +
            "." +
            month +
            "." +
            year +
            "&max_date=" +
            dayEnd +
            "." +
            monthEnd +
            "." +
            yearEnd;
        }

        store.dispatch("getAdminProductionLogs", {
          pk: statsEditedPk.value,
          params: params,
        });
      }
    );

    return {
      headers,
      adminsData,
      items,
      itemsAll,
      loading,
      serverOptions,
      machineActionsList,
      competenciesList,
      permissionsList,
      showModal,
      adminObj,
      editAdmin,
      cancel,
      saveEdit,
      deleteUser,
      deleteName,
      statsHeaders,
      statsItems,
      statsRangeDate,
      format,
      changePhoto,
      itemsSelected,
      groupActions,
      groupAction,
      bodyRowClassNameFunction,
      usersOnline,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
    };
  },
  created() {
    const route = useRoute();
    if (route.params?.pk) {
      setTimeout(() => {
        document
          .querySelector(".can-expand.direction-left")
          .nextSibling.click();
      }, 500);
    }
  },
};
</script>
