<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Montaże</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Montaże</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight logs">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Zamknięty</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.closed"
                      mode="tags"
                      placeholder="Zamknięty"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :search="true"
                      :options="filterOptions"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Zmontowany</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.mounted"
                      mode="tags"
                      placeholder="Zmontowany"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :search="true"
                      :options="filterOptions"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Naświetlony</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.radiated"
                      mode="tags"
                      placeholder="Zmontowany"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :search="true"
                      :options="filterOptions"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Wydrukowany</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.printed"
                      mode="tags"
                      placeholder="Wydrukowany"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :search="true"
                      :options="filterOptions"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Status RW</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="filters.rw_status"
                      mode="tags"
                      placeholder="Status RW"
                      track-by="name"
                      label="name"
                      :close-on-select="false"
                      :search="true"
                      :options="filterRWOptions"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Montaże</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/montages/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :loading="loading"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  show-index
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #item-quantity_brutto="item">
                    {{ (item.quantity_net + item.surplus_cost).toFixed(2) }}
                  </template>
                  <template #item-surplus_cost="item">
                    {{ item.surplus_cost.toFixed(2) }}
                  </template>
                  <template #item-percent="item">
                    {{ item.percent.toFixed(2) }}
                  </template>
                  <template #item-actions="item">
                    <router-link :to="'/montages/edit/' + item.pk">
                      <button class="btn btn-warning btn-xs">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </router-link>
                    <button
                      @click="getMontageCard(item.pk)"
                      title="Pobierz kartę"
                      class="btn btn-primary btn-xs"
                    >
                      <i class="fa fa-file"></i>
                    </button>
                    <button
                      @click="realization(item.pk)"
                      class="btn btn-primary btn-xs"
                      title="Do realizacji"
                    >
                      <i class="fa fa-list-alt"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>

          <div class="ibox-title">
            <h5>Podsumowanie</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div v-if="summary.percent !== 0" class="col-sm-12 m-b-xs">
                <EasyDataTable
                  :headers="headers"
                  :items="summary"
                  border-cell
                  hide-footer="true"
                >
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mActions" class="modal-dialog realization">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">
            Realizacja montażu nr:
            {{ realizationMontage.pk }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="panel-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="ibox">
                      <button
                        @click="
                          endProcess(
                            realizationMontage.pk,
                            !realizationMontage.closed,
                            'closed'
                          )
                        "
                        class="btn"
                        :class="{
                          'btn-primary': realizationMontage.closed,
                          'btn-default': !realizationMontage.closed,
                        }"
                      >
                        <i
                          v-if="realizationMontage.closed"
                          class="fa fa-check"
                        ></i>
                        <i v-else class="fa fa-times"></i>
                        <span> Zamknięty</span>
                      </button>

                      <button
                        @click="
                          endProcess(
                            realizationMontage.pk,
                            !realizationMontage.mounted,
                            'mounted'
                          )
                        "
                        :disabled="realizationMontage.radiated"
                        class="btn"
                        :class="{
                          'btn-primary': realizationMontage.mounted,
                          'btn-default': !realizationMontage.mounted,
                        }"
                      >
                        <i
                          v-if="realizationMontage.mounted"
                          class="fa fa-check"
                        ></i>
                        <i v-else class="fa fa-times"></i>
                        <span> Zmontowany</span>
                      </button>

                      <button
                        @click="
                          endProcess(
                            realizationMontage.pk,
                            !realizationMontage.radiated,
                            'radiated'
                          )
                        "
                        :disabled="realizationMontage.printed"
                        class="btn"
                        :class="{
                          'btn-primary': realizationMontage.radiated,
                          'btn-default': !realizationMontage.radiated,
                        }"
                      >
                        <i
                          v-if="realizationMontage.radiated"
                          class="fa fa-check"
                        ></i>
                        <i v-else class="fa fa-times"></i>
                        <span> Naświetlony</span>
                      </button>

                      <button
                        :disabled="realizationMontage.printed"
                        class="btn"
                        :class="{
                          'btn-primary': realizationMontage.printed,
                          'btn-default': !realizationMontage.printed,
                        }"
                      >
                        <i
                          v-if="realizationMontage.printed"
                          class="fa fa-check"
                        ></i>
                        <i v-else class="fa fa-times"></i>
                        <span> Wydrukowany</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row-eq-height row">
                  <div v-if="showProcessBox('closed')" class="col-md-3">
                    <div
                      class="nomargin height-100-pc widget lazur-bg p-xl"
                      :class="{ 'unactive-cube': realizationMontage.closed }"
                    >
                      <b>
                        <h2 v-if="realizationMontage.closed">Zamknięty</h2>
                        <h2 v-else>Zamykanie</h2>
                        <small v-if="realizationMontage.closed">
                          Poprzedni etap
                        </small>
                        <small v-else-if="!realizationMontage.closed">
                          Aktualny etap
                        </small>
                      </b>
                    </div>
                  </div>
                  <div v-if="showProcessBox('mounted')" class="col-md-3">
                    <div
                      class="nomargin height-100-pc widget lazur-bg p-xl"
                      :class="{
                        'unactive-cube':
                          currentProcess() !== 'mounted' ||
                          realizationMontage.mounted,
                      }"
                    >
                      <b>
                        <h2 v-if="realizationMontage.mounted">Zmontowany</h2>
                        <h2 v-else>Montaż</h2>
                        <small v-if="realizationMontage.mounted">
                          Poprzedni etap
                        </small>
                        <small
                          v-else-if="
                            realizationMontage.closed &&
                            !realizationMontage.mounted
                          "
                        >
                          Aktualny etap
                        </small>
                        <small v-else>Następny etap</small>
                      </b>
                    </div>
                  </div>
                  <div v-if="showProcessBox('radiated')" class="col-md-3">
                    <div
                      class="nomargin height-100-pc widget lazur-bg p-xl"
                      :class="{
                        'unactive-cube':
                          currentProcess() !== 'radiated' ||
                          realizationMontage.radiated,
                      }"
                    >
                      <b>
                        <h2 v-if="realizationMontage.radiated">Naświetlony</h2>
                        <h2 v-else>Naświetlanie</h2>
                        <small v-if="realizationMontage.radiated">
                          Poprzedni etap
                        </small>
                        <small v-else-if="realizationMontage.mounted">
                          Aktualny etap
                        </small>
                        <small v-else>Następny etap</small>
                      </b>
                    </div>
                  </div>

                  <div v-if="showProcessBox('printed')" class="col-md-3">
                    <div
                      class="nomargin height-100-pc widget lazur-bg p-xl"
                      :class="{
                        'unactive-cube': currentProcess() !== 'printed',
                      }"
                    >
                      <b>
                        <h2 v-if="realizationMontage.printed">Wydrukowany</h2>
                        <h2 v-else>Drukowanie</h2>
                        <small v-if="currentProcess() === 'printed'">
                          Aktualny etap
                        </small>
                        <small v-else>Następny etap</small>
                      </b>
                    </div>
                  </div>

                  <div class="col-md-3 border-left">
                    <div class="btn-group-vertical">
                      <button
                        @click="startProcess(realizationMontage.pk)"
                        :disabled="
                          realizationMontage.realization_started ||
                          realizationMontage.printed
                        "
                        class="btn btn-primary"
                      >
                        <span class="fa fa-play"></span>
                        <span> Rozpoczęcie pracy</span>
                      </button>
                      <button
                        @click="endProcess(realizationMontage.pk, true)"
                        class="btn btn-primary"
                        :disabled="
                          !realizationMontage.realization_started ||
                          realizationMontage.printed
                        "
                      >
                        <span class="fa fa-stop"></span>
                        <span> Zakończenie pracy</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="mActions = false" class="btn btn-white">
            Zamknij
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mActions" @click="mActions = false" class="modal-backdrop"></div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "MontagesList",
  mixins: [],
  components: {
    Multiselect,
  },
  props: {},

  setup() {
    const store = useStore();
    const montagesData = computed(() => store.getters.getMontagesData);
    const montageData = computed(() => store.getters.getMontageData);
    const montageProcessData = computed(
      () => store.getters.getMontageProcessData
    );
    let headers = [
      { text: "Nr montażu", value: "pk", sortable: true, search: true },
      { text: "Nazwa", value: "name", sortable: true, search: true },
      {
        text: "Nakład netto",
        value: "quantity_net",
        sortable: true,
        search: true,
      },
      {
        text: "Nakład brutto",
        value: "quantity_brutto",
        sortable: true,
        search: true,
      },
      { text: "Papier", value: "paper_option", search: true },
      {
        text: "Format arkusza",
        value: "sheet_format",
        search: true,
      },
      { text: "Ilość płyt", value: "plate_quantity", search: true },
      { text: "Rodzaj płyt", value: "plate", search: true },
      { text: "Wartość zleceń netto", value: "products_net_value" },
      { text: "Koszt montażu", value: "montage_cost" },
      { text: "Koszt naddatku", value: "surplus_cost" },
      { text: "Różnica", value: "difference" },
      { text: "Procent", value: "percent" },
      { text: "Wartość dodatkowa netto", value: "extra_net" },
      { text: "Termin", value: "realization_date" },
      { text: "Drukarz", value: "printer" },
      { text: "Data dodania", value: "date_added", sortable: true },
      { text: "Data modyfikacji", value: "date_modified", sortable: true },
      {
        text: "Zmodyfikowano przez",
        value: "last_modified_by",
        sortable: true,
      },
      {
        text: "Utworzono przez",
        value: "created_by",
        sortable: true,
      },
      { text: "Akcje", value: "actions" },
    ];

    const filterOptions = ref([
      { name: "Tak", value: "True" },
      { name: "Nie", value: "False" },
    ]);

    const filterRWOptions = ref([
      { name: "Oczekuje na wystawienie RW", value: "awaiting" },
      { name: "RW wystawione automatycznie", value: "auto_issued" },
      { name: "RW wystawione ręcznie", value: "manual_issued" },
      { name: "RW niewystawione automatycznie", value: "auto_not_issued" },
    ]);

    const loading = ref(true);
    const mActions = ref(false);
    const prodProcess = ref();
    const searchValue = ref({});
    const summary = ref([]);
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    const filters = ref({
      closed: [],
      mounted: [],
      printed: [],
      radiated: [],
      rw_status: [],
    });
    let items = ref([]);
    let itemsAll = ref(0);
    let statuses = ref([]);
    let realizationMontage = ref();
    const lastProcess = ref({
      type: "",
      val: "",
    });

    const getMontageCard = (pk) => {
      store.dispatch("getMontageCard", { pk: pk });
    };

    const getMontages = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getMontages", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
        filters: filters.value,
        isArchive: "False",
      });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getMontages();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getMontages();
    };

    const updateSort = () => {
      search.value = true;
    };

    const realization = (pk) => {
      store.dispatch("getMontage", { pk: pk });
    };

    const startProcess = (pk) => {
      let payload = {
        pk: pk,
        type: "/start_process/",
      };

      store.dispatch("startMontageProcess", payload);
    };

    const endProcess = (pk, val, process) => {
      let payload = {
        pk: pk,
        type: "/admin_state_update/",
      };

      if (!process) {
        payload[currentProcess()] = val;
        lastProcess.value.type = currentProcess();
        lastProcess.value.val = val;
      } else {
        payload[process] = val;
        lastProcess.value.type = process;
        lastProcess.value.val = val;
      }
      store.dispatch("startMontageProcess", payload);
    };

    const showProcessBox = (process) => {
      if (process === "closed") {
        if (realizationMontage.value.mounted) {
          return false;
        } else {
          return true;
        }
      } else if (process === "mounted") {
        if (
          realizationMontage.value.radiated &&
          realizationMontage.value.printed
        ) {
          return false;
        } else {
          return true;
        }
      } else if (process === "radiated") {
        if (
          !realizationMontage.value.closed &&
          !realizationMontage.value.mounted
        ) {
          return false;
        } else {
          return true;
        }
      } else if (process === "printed") {
        if (
          !realizationMontage.value.radiated &&
          !realizationMontage.value.mounted
        ) {
          return false;
        } else {
          return true;
        }
      }
    };

    const currentProcess = () => {
      let process;
      if (realizationMontage.value.mounted) {
        if (realizationMontage.value.radiated) {
          process = "printed";
        } else if (!realizationMontage.value.printed) {
          process = "radiated";
        }
      } else if (!realizationMontage.value.closed) {
        process = "closed";
      } else {
        process = "mounted";
      }

      return process;
    };

    const tableSummary = () => {
      let tempSummary = {
        quantity_net: 0,
        quantity_brutto: 0,
        products_net_value: 0,
        surplus_cost: 0,
        difference: 0,
        percent: 0,
        plate_quantity: 0,
      };
      summary.value = [];
      items.value?.forEach((element) => {
        tempSummary.quantity_net += element.quantity_net;
        tempSummary.quantity_brutto +=
          element.quantity_net + element.surplus_cost;
        tempSummary.products_net_value += element.products_net_value;
        tempSummary.surplus_cost += element.surplus_cost;
        tempSummary.difference += element.difference;
        tempSummary.plate_quantity += element.plate_quantity;
      });
      tempSummary.percent = (
        tempSummary.difference / tempSummary.quantity_net
      ).toFixed(2);
      tempSummary.quantity_net = tempSummary.quantity_net.toFixed(2);
      tempSummary.quantity_brutto = tempSummary.quantity_brutto.toFixed(2);
      tempSummary.products_net_value =
        tempSummary.products_net_value.toFixed(2);
      tempSummary.surplus_cost = tempSummary.surplus_cost.toFixed(2);
      tempSummary.difference = tempSummary.difference.toFixed(2);
      summary.value.push(tempSummary);
    };

    getMontages();

    watch(
      () => montagesData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        tableSummary();
        loading.value = false;
      }
    );

    watch(
      () => montageData.value,
      function (newValue) {
        mActions.value = true;
        realizationMontage.value = newValue.data;
      }
    );

    watch(
      () => montageProcessData.value,
      function (newValue) {
        if (newValue.data === "started") {
          store.dispatch("getMontage", { pk: realizationMontage.value.pk });
        } else {
          if (newValue.status === 200) {
            let responseText;
            if (lastProcess.value.type === "closed") {
              if (lastProcess.value.val) {
                responseText = "Montaż został zamknięty!";
              } else {
                responseText = "Montaż został otwarty!";
              }
            }
            if (lastProcess.value.type === "mounted") {
              if (lastProcess.value.val) {
                responseText = "Montaż został zmontowany!";
              } else {
                responseText = "Montowanie montażu zostało cofnięte!";
              }
            }
            if (lastProcess.value.type === "radiated") {
              if (lastProcess.value.val) {
                responseText = "Montaż został naświetlony!";
              } else {
                responseText = "Naświetlenie montażu zostało cofnięte!";
              }
            }
            if (lastProcess.value.type === "printed" && lastProcess.value.val) {
              responseText = "Montaż został wydrukowany!";
            }
            mActions.value = false;
            store.dispatch("showToaster", {
              type: "success",
              content: responseText,
            });
          } else {
            var msg = [];
            for (const [key, value] of Object.entries(newValue.data)) {
              msg.push(key + ": " + value);
            }
            store.dispatch("showToaster", {
              type: "error",
              content: msg[0],
            });
          }
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        getMontages();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getMontages();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getMontages();
          isSearch.value = false;
        }
      }
    );
    watch(
      () => filters.value,
      function () {
        getMontages();
      },
      { deep: true }
    );

    return {
      headers,
      serverOptions,
      items,
      itemsAll,
      loading,
      prodProcess,
      statuses,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
      filterOptions,
      filters,
      filterRWOptions,
      getMontageCard,
      mActions,
      realization,
      realizationMontage,
      startProcess,
      showProcessBox,
      endProcess,
      currentProcess,
      summary,
    };
  },
  computed: {},
};
</script>
