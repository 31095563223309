<template>
  <div id="wrapper" v-if="$route.path === '/login'">
    <router-view :key="$route.path"></router-view>
  </div>
  <router-view
    v-else-if="$route.name === 'AdminBarcode'"
    :key="$route.path"
  ></router-view>
  <div v-else id="wrapper">
    <Navigation />
    <div id="page-wrapper" class="gray-bg">
      <TopNavbar />
      <router-view :key="$route.path"></router-view>
      <Footer />
    </div>
  </div>
</template>

<script>
import Navigation from "./components/caffeprint/Navigation.vue";
import TopNavbar from "./components/caffeprint/TopNavbar.vue";
import Footer from "./components/caffeprint/Footer.vue";

export default {
  name: "App",
  mixins: [],
  components: {
    Navigation,
    TopNavbar,
    Footer,
  },
  setup() {
    return {};
  },
  mounted() {},
};
</script>
