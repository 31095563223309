<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Maszyny drukujące</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/plates">Maszyny drukujące</router-link>
        </li>
        <li class="active">
          <strong>Nowa</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Maszyny drukujące</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="plateObj.name"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Max format zadruku</label>
                <div class="col-sm-10 flex-inputs">
                  <div>
                    <input
                      v-model="plateObj.max_x"
                      name="width"
                      type="text"
                      class="form-control inputClass"
                      placeholder="x"
                      required="required"
                    />
                    <div v-if="errorsAdminData?.data?.max_x" class="has-error">
                      <span class="help-block">
                        {{ errorsAdminData.data.max_x[0] }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <input
                      v-model="plateObj.max_y"
                      name="max_y"
                      type="text"
                      class="form-control inputClass"
                      placeholder="y"
                      required="required"
                    />
                    <div v-if="errorsAdminData?.data?.max_y" class="has-error">
                      <span class="help-block">
                        {{ errorsAdminData.data.max_y[0] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Min format zadruku</label>
                <div class="col-sm-10 flex-inputs">
                  <div>
                    <input
                      v-model="plateObj.min_x"
                      name="width"
                      type="text"
                      class="form-control inputClass"
                      placeholder="x"
                      required="required"
                    />
                    <div v-if="errorsAdminData?.data?.min_x" class="has-error">
                      <span class="help-block">
                        {{ errorsAdminData.data.min_x[0] }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <input
                      v-model="plateObj.min_y"
                      name="min_y"
                      type="text"
                      class="form-control inputClass"
                      placeholder="y"
                      required="required"
                    />
                    <div v-if="errorsAdminData?.data?.min_y" class="has-error">
                      <span class="help-block">
                        {{ errorsAdminData.data.min_y[0] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Format matrycy</label>
                <div class="col-sm-10 flex-inputs">
                  <div>
                    <input
                      v-model="plateObj.format_x"
                      name="format_x"
                      type="text"
                      class="form-control inputClass"
                      placeholder="x"
                      required="required"
                    />
                    <div
                      v-if="errorsAdminData?.data?.format_x"
                      class="has-error"
                    >
                      <span class="help-block">
                        {{ errorsAdminData.data.format_x[0] }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <input
                      v-model="plateObj.format_y"
                      name="format_y"
                      type="text"
                      class="form-control inputClass"
                      placeholder="y"
                      required="required"
                    />
                    <div
                      v-if="errorsAdminData?.data?.format_y"
                      class="has-error"
                    >
                      <span class="help-block">
                        {{ errorsAdminData.data.format_y[0] }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <input
                      v-model="plateObj.cost"
                      name="format_y"
                      type="text"
                      class="form-control inputClass"
                      placeholder="Koszt [m2]"
                      required="required"
                    />
                    <div
                      v-if="errorsAdminData?.data?.format_y"
                      class="has-error"
                    >
                      <span class="help-block">
                        {{ errorsAdminData.data.format_y[0] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Wydajność [arkusz/h]
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="plateObj.efficiency"
                    name="price"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Wydajność [arkusz/h]"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.efficiency"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.efficiency[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Koszty eksploatacyjne stałe
                </label>
                <div class="col-sm-10 flex-inputs">
                  <div>
                    <input
                      v-model="plateObj.format_x"
                      name="format_x"
                      type="text"
                      class="form-control inputClass"
                      placeholder="[godzinę pracy]"
                      required="required"
                    />
                    <div
                      v-if="errorsAdminData?.data?.format_x"
                      class="has-error"
                    >
                      <span class="help-block">
                        {{ errorsAdminData.data.format_x[0] }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <input
                      v-model="plateObj.format_y"
                      name="format_y"
                      type="text"
                      class="form-control inputClass"
                      placeholder="[jednostkę przedruku]"
                      required="required"
                    />
                    <div
                      v-if="errorsAdminData?.data?.format_y"
                      class="has-error"
                    >
                      <span class="help-block">
                        {{ errorsAdminData.data.format_y[0] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/plates">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";

export default {
  name: "PlateesCreate",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const plateObj = ref({
      name: "",
      size_x: "",
      size_y: "",
      price: "",
    });

    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const createPlatesData = computed(() => store.getters.getCreatePlatesData);
    const create = () => {
      showErrors.value = true;
      store.dispatch("createPlates", plateObj.value);
    };

    watch(
      () => createPlatesData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router.push({ name: "PlatesList", path: "/plates" }).then(() => {
            store.dispatch("showToaster", {
              type: "success",
              content: "Nowa płyta została dodana",
            });
          });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      plateObj,
      errorsAdminData,
      showErrors,
      create,
    };
  },
  computed: {},
};
</script>
