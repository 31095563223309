<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Wysyłka zamówień</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Wysyłka zamówień</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Wysyłka zamówień</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Numer zamówienia
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="orderId"
                    name="username"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Numer zamówienia"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.detail }}
                    </span>
                  </div>
                  <div
                    v-if="errorsAdminData?.data?.nonFieldErrors"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.nonFieldErrors[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <button @click="send" class="btn btn-primary">Wyślij</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";

export default {
  name: "OrderSend",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const orderId = ref();
    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const sendOrderData = computed(() => store.getters.getSendOrderData);

    const send = () => {
      showErrors.value = true;
      if (orderId.value) {
        store.dispatch("sendOrder", orderId.value);
      }
    };

    watch(
      () => sendOrderData.value,
      function (newValue) {
        if (newValue.status === 200) {
          router.push({
            name: "OrderPacking",
            path: "/order/send/",
            params: { pk: orderId.value },
          });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      send,
      errorsAdminData,
      showErrors,
      orderId,
    };
  },
  computed: {},
};
</script>
