<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Kompetencje</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/discount-groups/list">Grupy rabatowe</router-link>
        </li>
        <li class="active">
          <strong>Nowa</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Grupy rabatowe</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa [PL]</label>
                <div class="col-sm-10">
                  <input
                    v-model="dGroupObj.name_pl"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [PL]"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_pl" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa [DE]</label>
                <div class="col-sm-10">
                  <input
                    v-model="dGroupObj.name_de"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [DE]"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_de" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa [EN]</label>
                <div class="col-sm-10">
                  <input
                    v-model="dGroupObj.name_en"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [EN]"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_en" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa [DE_DE]</label>
                <div class="col-sm-10">
                  <input
                    v-model="dGroupObj.name_cpde"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [DE_DE]"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.name_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.name_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="username" class="control-label col-sm-2">
                  Nazwa [DE_EN]
                </label>
                <div class="col-sm-10">
                  <input
                    id="username"
                    v-model="dGroupObj.name_cpen"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa [DE_EN]"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.name_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.name_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <label class="control-label col-sm-2">Obrót netto</label>
                <div class="col-sm-10">
                  <input
                    v-model="dGroupObj.net_turnover"
                    type="number"
                    class="form-control inputClass"
                    placeholder="Obrót netto"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.net_turnover"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.net_turnover[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Liczba zamówień</label>
                <div class="col-sm-10">
                  <input
                    v-model="dGroupObj.orders_count"
                    type="number"
                    class="form-control inputClass"
                    placeholder="Liczba zamówień"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.orders_count"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.orders_count[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Liczba dni</label>
                <div class="col-sm-10">
                  <input
                    v-model="dGroupObj.days_count"
                    type="number"
                    class="form-control inputClass"
                    placeholder="Liczba dni"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.days_count"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.days_count[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2"> Procent</label>
                <div class="col-sm-10">
                  <input
                    v-model="dGroupObj.value"
                    type="number"
                    class="form-control inputClass"
                    placeholder="Procent"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.value" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.value[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/discount-groups/list">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";

export default {
  name: "CompetenciesCreate",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const dGroupObj = ref({});

    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const createDiscountGroupsData = computed(
      () => store.getters.getCreateDiscountGroupsData
    );

    const create = () => {
      showErrors.value = true;
      store.dispatch("createDiscountGroup", dGroupObj.value);
    };

    watch(
      () => createDiscountGroupsData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router
            .push({ name: "DiscountGroupsList", path: "/discount-groups/list" })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Grupa rabatowa została dodana",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      dGroupObj,
      errorsAdminData,
      showErrors,
      create,
    };
  },
  computed: {},
};
</script>
