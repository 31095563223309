<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Zmienne</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Zmienne</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Zmienne</h5>
          </div>
          <div class="ibox-content">
            <button @click="addNew" class="btn btn-sm btn-primary">
              Dodaj
            </button>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editPlate"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #item-actions="item">
                    <div v-if="item.isNew">
                      <button
                        @click="addNewSend"
                        class="btn btn-success btn-xs"
                      >
                        <i class="fa fa-check"></i>
                      </button>
                      <button @click="cancelNew" class="btn btn-danger btn-xs">
                        <span class="fa fa-times"></span>
                      </button>
                    </div>
                    <div v-else>
                      <button
                        v-if="item.isEdit"
                        @click="editSend"
                        class="btn btn-success btn-xs"
                      >
                        <i class="fa fa-check"></i>
                      </button>
                      <button
                        v-if="item.isEdit"
                        @click="cancelEdit"
                        class="btn btn-danger btn-xs"
                      >
                        <span class="fa fa-times"></span>
                      </button>
                      <button
                        v-if="!item.isEdit"
                        @click="editRow(item)"
                        class="btn btn-warning btn-xs"
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                      <button
                        v-if="!item.isEdit"
                        @click.stop="
                          showModal = item.pk;
                          deleteName = item.name;
                        "
                        class="btn btn-danger btn-xs"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </template>
                  <template #item-name="item">
                    <div v-if="item.isNew || item.isEdit">
                      <div class="col-sm-10">
                        <input
                          v-model="variableObj.name"
                          name="name"
                          type="text"
                          class="form-control inputClass"
                          placeholder="Nazwa"
                          required="required"
                        />
                        <div
                          v-if="errorsAdminData?.data?.name"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ errorsAdminData.data.size_x[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span v-else>
                      {{ item.name }}
                    </span>
                  </template>
                  <template #item-value="item">
                    <div v-if="item.isNew || item.isEdit" class="form-group">
                      <div class="col-sm-10">
                        <input
                          v-model="variableObj.value"
                          name="value"
                          type="checkbox"
                        />
                        <div
                          v-if="errorsAdminData?.data?.name"
                          class="has-error"
                        >
                          <span class="help-block">
                            {{ errorsAdminData.data.name[0] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span v-else>
                      <i v-if="item.value" class="fa fa-check"></i>
                      <i v-else class="fa fa-times"></i>
                    </span>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deleteVariables(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";

export default {
  name: "VariablesList",
  mixins: [],
  components: {
    Modal,
  },
  props: {},

  setup() {
    const store = useStore();
    const deleteVariablesData = computed(
      () => store.getters.getDeleteVariablesData
    );
    const createVariablesData = computed(
      () => store.getters.getCreateVariablesData
    );
    const variableData = computed(() => store.getters.getVariableData);
    const updateVariablesData = computed(
      () => store.getters.getUpdateVariablesData
    );
    const variablesData = computed(() => store.getters.getVariablesData);
    const variableObj = ref({
      name: "",
      value: false,
    });
    const errorsAdminData = ref();
    let headers = [
      { text: "Nr", value: "pk" },
      { text: "Nazwa", value: "name", sortable: true, search: true },
      { text: "Wartość", value: "value", sortable: true },
      {
        text: "Data dodania",
        value: "date_added",
        sortable: true,
        search: true,
      },
      {
        text: "Data modyfikacji",
        value: "date_modified",
        sortable: true,
        search: true,
      },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const getVariables = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getVariables", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const deleteVariables = (pk) => {
      showModal.value = false;
      store.dispatch("deleteVariables", {
        pk: pk,
      });
    };
    const saveEdit = () => {
      store.dispatch("updateVariables", variableObj.value);
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const addNew = () => {
      for (const [key] of Object.entries(items.value)) {
        items.value[key].isEdit = false;
        items.value[key].isNew = false;
      }
      items.value.push({
        pk: items.value[items.value.length - 1]?.pk + 1,
        name: "",
        value: false,
        isNew: true,
        isEdit: true,
      });
    };

    const addNewSend = () => {
      store.dispatch("createVariables", variableObj.value);
    };

    const editSend = () => {
      store.dispatch("updateVariables", variableObj.value);
    };

    const cancelNew = () => {
      items.value.splice(-1);
      variableObj.value = {
        name: "",
        value: "",
      };
    };

    const cancelEdit = (pk) => {
      for (const [key, value] of Object.entries(items.value)) {
        items.value[key].isEdit = false;
        if (value.pk === pk) {
          items.value[key].isEdit = false;
          items.value[key].isNew = false;
        }
      }
    };

    const editRow = (item) => {
      var keyObj = false;
      for (const [key, value] of Object.entries(items.value)) {
        items.value[key].isEdit = false;
        if (value.pk === item.pk) {
          keyObj = key;
        }
      }

      if (keyObj) {
        variableObj.value = {
          name: item.name,
          value: item.value,
          pk: item.pk,
        };
        items.value[keyObj].isEdit = true;
      }
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getVariables();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getVariables();
    };

    const updateSort = () => {
      search.value = true;
    };

    getVariables();

    watch(
      () => variablesData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => variableData.value,
      function (newValue) {
        variableObj.value = newValue.data;
      }
    );

    watch(
      () => deleteVariablesData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Zmienna została usunieta",
          });
        }
        getVariables();
      }
    );

    watch(
      () => createVariablesData.value,
      function (newValue) {
        if (newValue.status === 201) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Zmienna została dodana",
          });
        }
        getVariables();
      }
    );

    watch(
      () => updateVariablesData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getVariables();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getVariables();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getVariables();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      deleteVariables,
      deleteName,
      variableObj,
      cancel,
      saveEdit,
      addNew,
      addNewSend,
      cancelNew,
      cancelEdit,
      editRow,
      errorsAdminData,
      editSend,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
    };
  },
  computed: {},
};
</script>
