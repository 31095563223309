<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Strony</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Strony</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Strony</h5>
          </div>
          <div class="ibox-content">
            <router-link to="/pages/create">
              <button class="btn btn-sm btn-primary">Dodaj</button>
            </router-link>
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <button @click="resetFilters" class="btn btn-primary btn-xs">
                  <i class="fa fa-refresh"></i>
                </button>
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :clickRowToExpand="true"
                  :loading="loading"
                  @click-row="editPages"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                  <template #expand>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Układ strony</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Układ strony
                                </label>
                                <div class="col-sm-10">
                                  <select
                                    v-model="pageObj.layout"
                                    class="form-control m-b ng-pristine"
                                  >
                                    <option
                                      v-for="(type, index) in pageLayouts"
                                      :value="type.value"
                                      :key="index"
                                    >
                                      {{ type.name }}
                                    </option>
                                  </select>
                                  <div
                                    v-if="errorsAdminData?.data?.layout"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.layout[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis strony [pl]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Adres strony
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.url_pl"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Adres strony"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.url_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.url_pl[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.title_seo_pl"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_seo_pl"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_seo_pl[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Słowa kluczowe
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.keywords_seo_pl"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Słowa kluczowe"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.keywords_seo_pl
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.keywords_seo_pl[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Opis
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.description_seo_pl"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Opis"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.description_seo_pl
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .description_seo_pl[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść - Kolumna 1
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      pageObj.first_col_content_pl
                                    "
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.first_col_content_pl
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .first_col_content_pl[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść - Kolumna 2
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      pageObj.second_col_content_pl
                                    "
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.second_col_content_pl
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .second_col_content_pl[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis strony [de]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Adres strony
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.url_de"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Adres strony"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.url_de"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.url_de[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.title_seo_de"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_seo_de"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_seo_de[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Słowa kluczowe
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.keywords_seo_de"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Słowa kluczowe"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.keywords_seo_de
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.keywords_seo_de[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Opis
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.description_seo_de"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Opis"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.description_seo_de
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .description_seo_de[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść - Kolumna 1
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      pageObj.first_col_content_de
                                    "
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.first_col_content_de
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .first_col_content_de[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść - Kolumna 2
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      pageObj.second_col_content_de
                                    "
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.second_col_content_de
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .second_col_content_de[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis strony [en]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Adres strony
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    id="username"
                                    v-model="pageObj.url_en"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Adres strony"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.url_en"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.url_en[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.title_seo_en"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_seo_en"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.title_seo_en[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Słowa kluczowe
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.keywords_seo_en"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Słowa kluczowe"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.keywords_seo_en
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.keywords_seo_en[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Opis
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.description_seo_en"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Opis"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.description_seo_en
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .description_seo_en[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść - Kolumna 1
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      pageObj.first_col_content_en
                                    "
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.first_col_content_en
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .first_col_content_en[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść - Kolumna 2
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      pageObj.second_col_content_en
                                    "
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.second_col_content_en
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .second_col_content_en[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis strony [de_de]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Adres strony
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.url_cpde"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Adres strony"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.url_cpde"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.url_cpde[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.title_seo_cpde"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_seo_cpde"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.title_seo_cpde[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Słowa kluczowe
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.keywords_seo_cpde"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Słowa kluczowe"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.keywords_seo_cpde
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .keywords_seo_cpde[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Opis
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.description_seo_cpde"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Opis"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.description_seo_cpde
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .description_seo_cpde[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść - Kolumna 1
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      pageObj.first_col_content_cpde
                                    "
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.first_col_content_cpde
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .first_col_content_cpde[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść - Kolumna 2
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      pageObj.second_col_content_cpde
                                    "
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.second_col_content_cpde
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .second_col_content_cpde[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Opis strony [de_en]</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Adres strony
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.url_cpen"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="Adres strony"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.url_cpen"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.url_cpen[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Tytuł
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.title_seo_cpen"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Tytuł"
                                    required="required"
                                  />
                                  <div
                                    v-if="errorsAdminData?.data?.title_seo_cpen"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.title_seo_cpen[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Słowa kluczowe
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.keywords_seo_cpen"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Słowa kluczowe"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.keywords_seo_cpen
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .keywords_seo_cpen[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  [SEO] Opis
                                </label>
                                <div class="col-sm-10">
                                  <input
                                    v-model="pageObj.description_seo_cpen"
                                    type="text"
                                    class="form-control inputClass"
                                    placeholder="[SEO] Opis"
                                    required="required"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.description_seo_cpen
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .description_seo_cpen[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść - Kolumna 1
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      pageObj.first_col_content_cpen
                                    "
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.first_col_content_cpen
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .first_col_content_cpen[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Treść - Kolumna 2
                                </label>
                                <div class="col-sm-10 QuillEditor-box">
                                  <QuillEditor
                                    theme="snow"
                                    contentType="html"
                                    v-model:content="
                                      pageObj.second_col_content_cpen
                                    "
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data
                                        ?.second_col_content_cpen
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data
                                          .second_col_content_cpen[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="ibox float-e-margins">
                          <div class="ibox-title">
                            <h5>Konfiguracja strony</h5>
                          </div>

                          <div class="form-horizontal">
                            <div class="ibox-content">
                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Typ strony
                                </label>
                                <div class="col-sm-10">
                                  <select
                                    v-model="pageObj.type"
                                    class="form-control m-b ng-pristine"
                                  >
                                    <option
                                      v-for="(type, index) in pageTypes"
                                      :value="type.value"
                                      :key="index"
                                    >
                                      {{ type.name }}
                                    </option>
                                  </select>
                                  <div
                                    v-if="errorsAdminData?.data?.type"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.type[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Menu
                                </label>
                                <div class="col-sm-10">
                                  <select
                                    v-model="pageObj.menu"
                                    class="form-control m-b ng-pristine"
                                  >
                                    <option
                                      v-for="(type, index) in pageMenu"
                                      :value="type.value"
                                      :key="index"
                                    >
                                      {{ type.name }}
                                    </option>
                                  </select>
                                  <div
                                    v-if="errorsAdminData?.data?.menu"
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{ errorsAdminData.data.menu[0] }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-10">
                                  <div class="checkbox checkbox-primary">
                                    <label>
                                      <input
                                        v-model="pageObj.in_sitemap"
                                        name="is_published"
                                        type="checkbox"
                                      />
                                      <span>Publikuj w mapie serwisu</span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="control-label col-sm-2">
                                  Data publikacji
                                </label>
                                <div class="col-sm-10">
                                  <VueDatePicker
                                    :format="format"
                                    v-model="publication_date"
                                  />
                                  <div
                                    v-if="
                                      errorsAdminData?.data?.publication_date
                                    "
                                    class="has-error"
                                  >
                                    <span class="help-block">
                                      {{
                                        errorsAdminData.data.publication_date[0]
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="hr-line-dashed"></div>

                              <div class="form-group">
                                <div class="col-sm-4 col-sm-offset-2">
                                  <button class="btn btn-white" @click="cancel">
                                    Anuluj
                                  </button>
                                  <button
                                    @click="saveEdit"
                                    class="btn btn-primary"
                                  >
                                    Zapisz
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #item-actions="item">
                    <button
                      @click.stop="
                        showModal = item.pk;
                        deleteName = item.title_seo;
                      "
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showModal"
    :title="'Potwierdzenie usunięcia'"
    :content="'Czy na pewno chcesz usunąć <b>' + deleteName + '</b> ?'"
    @callbackOk="deletePage(showModal)"
    @callbackClose="showModal = false"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import Modal from "../caffeprint/Modal.vue";

export default {
  name: "PagesList",
  mixins: [],
  components: {
    Modal,
  },
  props: {},

  setup() {
    const store = useStore();
    const deletePageData = computed(() => store.getters.getDeletePagesData);
    const pageData = computed(() => store.getters.getPageData);
    const updatePagesData = computed(() => store.getters.getUpdatePagesData);
    const pagesData = computed(() => store.getters.getPagesData);
    const pageTypesData = computed(() => store.getters.getPageTypesData);
    const pageLayoutsData = computed(() => store.getters.getPageLayoutsData);
    const pageMenuData = computed(() => store.getters.getPageMenuData);
    const pageObj = ref({
      ip: "",
      description: "",
    });
    let headers = [
      { text: "Nr", value: "pk" },
      { text: "Adres strony", value: "url", sortable: true, search: true },
      { text: "Tytuł", value: "title_seo", sortable: true, search: true },
      { text: "Typ", value: "type", sortable: true, search: true },
      { text: "Menu", value: "menu", sortable: true, search: true },
      { text: "Akcje", value: "actions" },
    ];

    let items = ref([]);
    let statsItems = ref([]);
    let itemsAll = ref(0);
    const showModal = ref(false);
    const deleteName = ref("");
    const loading = ref(true);
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const pageTypes = ref([]);
    const pageLayouts = ref([]);
    const pageMenu = ref([]);
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });

    const getPages = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getPages", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        search: searchValue.value,
      });
    };

    const deletePage = (pk) => {
      showModal.value = false;
      store.dispatch("deletePages", {
        pk: pk,
      });
    };
    const saveEdit = () => {
      store.dispatch("updatePages", pageObj.value);
    };

    const cancel = () => {
      document.querySelector(".expanding")?.click();
    };

    const editPages = (item) => {
      cancel();
      store.dispatch("getPage", { pk: item.pk });
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getPages();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getPages();
    };

    const updateSort = () => {
      search.value = true;
    };

    const format = (date) => {
      let day, month, year, hours, minutes, seconds;
      day = date.getDate();
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      year = date.getFullYear();
      minutes = ("0" + date.getMinutes()).slice(-2);
      seconds = ("0" + date.getSeconds()).slice(-2);
      hours = date.getHours();

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    };

    getPages();
    store.dispatch("getPageTypes");
    store.dispatch("getPageLayouts");
    store.dispatch("getPageMenu");

    watch(
      () => pagesData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => pageData.value,
      function (newValue) {
        pageObj.value = newValue.data;
      }
    );

    watch(
      () => deletePageData.value,
      function (newValue) {
        if (newValue.status === 204) {
          store.dispatch("showToaster", {
            type: "success",
            content: "Strona została usunięty",
          });
        }
        getPages();
      }
    );

    watch(
      () => updatePagesData.value,
      function (newValue) {
        if (newValue.status === 200) {
          getPages();
          store.dispatch("showToaster", {
            type: "success",
            content: "Dane zostały zaktualizowane",
          });
        }

        cancel();
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getPages();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getPages();
          isSearch.value = false;
        }
      }
    );

    watch(
      () => pageTypesData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          pageTypes.value.push({
            name: element[1],
            value: element[0],
          });
        });
      }
    );

    watch(
      () => pageLayoutsData.value,
      function (newValue) {
        pageLayouts.value = [];
        newValue.data?.forEach((element) => {
          pageLayouts.value.push({
            name: element[1],
            value: element[0],
          });
        });
      }
    );

    watch(
      () => pageMenuData.value,
      function (newValue) {
        pageMenu.value = [];
        newValue.data?.forEach((element) => {
          pageMenu.value.push({
            name: element[1],
            value: element[0],
          });
        });
      }
    );

    return {
      headers,
      items,
      itemsAll,
      loading,
      serverOptions,
      showModal,
      deletePage,
      deleteName,
      statsItems,
      pageObj,
      cancel,
      editPages,
      saveEdit,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
      pageTypes,
      pageLayouts,
      pageMenu,
      format,
    };
  },
  computed: {},
};
</script>
