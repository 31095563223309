<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Strony</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/pages">Strony</router-link>
        </li>
        <li class="active">
          <strong>Nowa</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Układ strony</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Układ strony</label>
                <div class="col-sm-10">
                  <select
                    v-model="pageObj.layout"
                    class="form-control m-b ng-pristine"
                  >
                    <option
                      v-for="(type, index) in pageLayouts"
                      :value="type.value"
                      :key="index"
                    >
                      {{ type.name }}
                    </option>
                  </select>
                  <div v-if="errorsAdminData?.data?.layout" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.layout[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis strony [pl]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Adres strony</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.url_pl"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Adres strony"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.url_pl" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.url_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.title_seo_pl"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.keywords_seo_pl"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.description_seo_pl"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Treść - Kolumna 1
                </label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="pageObj.first_col_content_pl"
                  />
                  <div
                    v-if="errorsAdminData?.data?.first_col_content_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.first_col_content_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Treść - Kolumna 2
                </label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="pageObj.second_col_content_pl"
                  />
                  <div
                    v-if="errorsAdminData?.data?.second_col_content_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.second_col_content_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis strony [de]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Adres strony</label>
                <div class="col-sm-10">
                  <input
                    id="username"
                    v-model="pageObj.url_de"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Adres strony"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.url_de" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.url_de[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.title_seo_de"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_de[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.keywords_seo_de"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.description_seo_de"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Treść - Kolumna 1
                </label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="pageObj.first_col_content_de"
                  />
                  <div
                    v-if="errorsAdminData?.data?.first_col_content_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.first_col_content_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Treść - Kolumna 2
                </label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="pageObj.second_col_content_de"
                  />
                  <div
                    v-if="errorsAdminData?.data?.second_col_content_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.second_col_content_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis strony [en]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Adres strony</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.url_en"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Adres strony"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.url_en" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.url_en[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.title_seo_en"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_en[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.keywords_seo_en"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.description_seo_en"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Treść - Kolumna 1
                </label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="pageObj.first_col_content_en"
                  />
                  <div
                    v-if="errorsAdminData?.data?.first_col_content_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.first_col_content_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Treść - Kolumna 2
                </label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="pageObj.second_col_content_en"
                  />
                  <div
                    v-if="errorsAdminData?.data?.second_col_content_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.second_col_content_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis strony [de_de]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Adres strony</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.url_cpde"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Adres strony"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.url_cpde" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.url_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.title_seo_cpde"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.keywords_seo_cpde"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.description_seo_cpde"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Treść - Kolumna 1
                </label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="pageObj.first_col_content_cpde"
                  />
                  <div
                    v-if="errorsAdminData?.data?.first_col_content_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.first_col_content_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Treść - Kolumna 2
                </label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="pageObj.second_col_content_cpde"
                  />
                  <div
                    v-if="errorsAdminData?.data?.second_col_content_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.second_col_content_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis strony [de_en]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Adres strony</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.url_cpen"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Adres strony"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.url_cpen" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.url_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.title_seo_cpen"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.keywords_seo_cpen"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="pageObj.description_seo_cpen"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Treść - Kolumna 1
                </label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="pageObj.first_col_content_cpen"
                  />
                  <div
                    v-if="errorsAdminData?.data?.first_col_content_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.first_col_content_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Treść - Kolumna 2
                </label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="pageObj.second_col_content_cpen"
                  />
                  <div
                    v-if="errorsAdminData?.data?.second_col_content_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.second_col_content_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Konfiguracja strony</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Typ strony</label>
                <div class="col-sm-10">
                  <select
                    v-model="pageObj.type"
                    class="form-control m-b ng-pristine"
                  >
                    <option
                      v-for="(type, index) in pageTypes"
                      :value="type.value"
                      :key="index"
                    >
                      {{ type.name }}
                    </option>
                  </select>
                  <div v-if="errorsAdminData?.data?.type" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.type[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Menu</label>
                <div class="col-sm-10">
                  <select
                    v-model="pageObj.menu"
                    class="form-control m-b ng-pristine"
                  >
                    <option
                      v-for="(type, index) in pageMenu"
                      :value="type.value"
                      :key="index"
                    >
                      {{ type.name }}
                    </option>
                  </select>
                  <div v-if="errorsAdminData?.data?.menu" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.menu[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="pageObj.in_sitemap"
                        name="is_published"
                        type="checkbox"
                      />
                      <span>Publikuj w mapie serwisu</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Data publikacji</label>
                <div class="col-sm-10">
                  <VueDatePicker :format="format" v-model="publication_date" />
                  <div
                    v-if="errorsAdminData?.data?.publication_date"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.publication_date[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="hr-line-dashed"></div>

              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/pages">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";

export default {
  name: "PagesCreate",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const pageObj = ref({
      url: "",
      description: "",
    });

    const countryList = ref([
      { name: "Polska", value: "PL" },
      { name: "Niemcy", value: "DE" },
    ]);

    const errorsAdminData = ref();
    const pageTypes = ref([]);
    const pageLayouts = ref([]);
    const pageMenu = ref([]);
    const showErrors = ref(false);
    const store = useStore();
    const createPagesData = computed(() => store.getters.getCreatePagesData);
    const pageTypesData = computed(() => store.getters.getPageTypesData);
    const pageLayoutsData = computed(() => store.getters.getPageLayoutsData);
    const pageMenuData = computed(() => store.getters.getPageMenuData);

    const create = () => {
      showErrors.value = true;
      store.dispatch("createPages", pageObj.value);
    };

    const format = (date) => {
      let day, month, year, hours, minutes, seconds;
      day = date.getDate();
      month = ("0" + (date.getMonth() + 1)).slice(-2);
      year = date.getFullYear();
      minutes = ("0" + date.getMinutes()).slice(-2);
      seconds = ("0" + date.getSeconds()).slice(-2);
      hours = date.getHours();

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    };

    store.dispatch("getPageTypes");
    store.dispatch("getPageLayouts");
    store.dispatch("getPageMenu");

    watch(
      () => createPagesData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router.push({ name: "PagesList", path: "/pages" }).then(() => {
            store.dispatch("showToaster", {
              type: "success",
              content: "Strona została dodany",
            });
          });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    watch(
      () => pageTypesData.value,
      function (newValue) {
        newValue.data?.forEach((element) => {
          pageTypes.value.push({
            name: element[1],
            value: element[0],
          });
        });
      }
    );

    watch(
      () => pageLayoutsData.value,
      function (newValue) {
        pageLayouts.value = [];
        newValue.data?.forEach((element) => {
          pageLayouts.value.push({
            name: element[1],
            value: element[0],
          });
        });
      }
    );

    watch(
      () => pageMenuData.value,
      function (newValue) {
        pageMenu.value = [];
        newValue.data?.forEach((element) => {
          pageMenu.value.push({
            name: element[1],
            value: element[0],
          });
        });
      }
    );

    return {
      countryList,
      pageObj,
      errorsAdminData,
      showErrors,
      create,
      pageTypes,
      pageLayouts,
      pageMenu,
      format,
    };
  },
  computed: {},
};
</script>
