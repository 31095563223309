<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Pliki</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/file/list">Pliki</router-link>
        </li>
        <li class="active">
          <strong>Nowy</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis pliku [pl]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="fileObj.name_pl"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_pl" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis pliku [en]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="fileObj.name_en"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Tytuł"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_en" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis pliku [de]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="fileObj.name_de"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_de" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis pliku [de_de]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="fileObj.name_cpde"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.name_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.name_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis pliku [de_en]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="fileObj.name_cpen"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.name_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.name_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Konfiguracja Pliku</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Plik</label>
                <div class="col-sm-10">
                  <div class="fileinput fileinput-new input-group">
                    <div class="form-control">
                      <input
                        type="file"
                        name="file"
                        v-on:change="onFileChange"
                        multiple="multiple"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/file/list">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";

export default {
  name: "fileCreate",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const fileObj = ref({
      name_pl: "",
      name_en: "",
      name_de: "",
      name_cpde: "",
      name_cpen: "",
      file: "",
    });
    const errorsAdminData = ref();
    const showErrors = ref(false);
    const store = useStore();
    const createFilesData = computed(() => store.getters.getCreateFilesData);

    const create = () => {
      showErrors.value = true;
      store.dispatch("createFile", fileObj.value);
    };

    const onFileChange = (e) => {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      fileObj.value.file = files[0];
    };

    watch(
      () => createFilesData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router
            .push({
              name: "FilesList",
              path: "/file/list",
            })
            .then(() => {
              store.dispatch("showToaster", {
                type: "success",
                content: "Nowy plik został dodany",
              });
            });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      fileObj,
      errorsAdminData,
      showErrors,
      create,
      onFileChange,
    };
  },
  computed: {},
};
</script>
