<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Logi produkcyjne</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Logi produkcyjne</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight logs">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-3 nopadding">
                <div class="form-group">
                  <label class="control-label col-sm-2">Wybierz daty</label>
                  <div class="col-sm-8">
                    <VueDatePicker
                      class="stats-daterangepicker"
                      v-model="statsRangeDate"
                      multi-calendars
                      :format="format"
                      range
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-2 nopadding">
                <div class="form-group">
                  <label class="col-sm-2 control-label">
                    Proces produkcyjny
                  </label>
                  <div class="col-sm-10">
                    <select
                      v-model="productionProcess"
                      class="form-control m-b ng-pristine"
                    >
                      <option
                        v-for="(status, key) in statuses"
                        :value="status[0]"
                        :key="key"
                      >
                        {{ status[1] }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Podsumowanie</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div v-if="summary" class="col-sm-12 m-b-xs">
                {{ summary.sum }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Logi produkcyjne</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-sm-12 m-b-xs">
                <div class="form-group">
                  <label class="control-label col-sm-2">
                    Wpisz liczbę wierszy:
                  </label>
                  <div class="col-sm-2">
                    <input
                      class="form-control inputClass"
                      type="text"
                      v-model="rowsPerPage"
                      @click="isSearch = false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 m-b-xs" v-if="items">
                <EasyDataTable
                  v-model:server-options="serverOptions"
                  :server-items-length="itemsAll"
                  :headers="headers"
                  :items="items"
                  :loading="loading"
                  alternating
                  multi-sort
                  border-cell
                  @update-sort="updateSort"
                  class="table-search"
                >
                  <template #header="header">
                    <span v-if="header.text">{{ header.text }}</span>
                    <input
                      class="table-search"
                      type="text"
                      v-if="header.search"
                      v-model="searchValue[header.value]"
                      @keyup="search"
                      @click="isSearch = true"
                    />
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "ProductionlogsList",
  mixins: [],
  components: {},
  props: {},

  setup() {
    const store = useStore();
    const prodlogsData = computed(() => store.getters.getProdlogsData);
    const logStatuses = computed(() => store.getters.getLogStatusesData);
    const summaryData = computed(() => store.getters.getProdLogsSummaryData);
    let headers = [
      { text: "Nr", value: "pk" },
      { text: "Data zdarzenia", value: "created", sortable: true },
      { text: "Użytkownik", value: "user", sortable: true, search: true },
      {
        text: "Zmieniony proces",
        value: "changed_object_name",
        sortable: true,
      },
      { text: "NR Zamówienia", value: "order_id", search: true },
      {
        text: "ID pozycji zamówienia",
        value: "order_product_id",
        search: true,
      },
      { text: "Nr montażu", value: "montage_nr", search: true },
      { text: "Status przed zmianą", value: "old_values" },
      { text: "Status po zmianie", value: "new_values" },
      { text: "Data rozpoczęcia", value: "start_date" },
      { text: "Data zakończenia", value: "end_date" },
      { text: "Czas", value: "time" },
    ];
    const loading = ref(true);
    const prodProcess = ref();
    const searchValue = ref({});
    const isSearch = ref(false);
    const rowsPerPage = ref();
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 25,
    });
    const statsRangeDate = ref();
    let items = ref([]);
    let itemsAll = ref(0);
    let statuses = ref([]);
    let productionProcess = ref("");
    let summary = ref("");
    store.dispatch("getLogStatuses");
    store.dispatch("getProdLogsSummary");

    const getProdlogs = async () => {
      loading.value = true;
      let sort;
      if (serverOptions.value.sortType === "desc") {
        sort = "-" + serverOptions.value.sortBy;
      } else {
        sort = serverOptions.value.sortBy;
      }
      await store.dispatch("getProdlogs", {
        page: serverOptions.value.page,
        itemsPerPage: serverOptions.value.rowsPerPage,
        ordering: sort,
        status: productionProcess.value,
        date: statsRangeDate.value,
        search: searchValue.value,
      });
    };

    const format = (date) => {
      let day, month, year, dayEnd, monthEnd, yearEnd;
      if (date[0]) {
        day = date[0].getDate();
        month = date[0].getMonth() + 1;
        year = date[0].getFullYear();
      }

      if (date[1]) {
        dayEnd = date[1].getDate();
        monthEnd = date[1].getMonth() + 1;
        yearEnd = date[1].getFullYear();
      }

      if (date.length > 1) {
        return `${day}/${month}/${year} - ${dayEnd}/${monthEnd}/${yearEnd}`;
      }
    };

    const search = () => {
      isSearch.value = true;
      if (searchValue.value) {
        setTimeout(() => {
          getProdlogs();
        }, 1000);
      }
    };

    const resetFilters = () => {
      searchValue.value = {};
      serverOptions.value.sortBy = "";
      getProdlogs();
    };

    const updateSort = () => {
      search.value = true;
    };

    getProdlogs();

    watch(
      () => prodlogsData.value,
      function (newValue) {
        itemsAll.value = newValue.data.count;
        items.value = [];
        newValue.data.results?.forEach((element) => {
          items.value.push(element);
        });
        loading.value = false;
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        getProdlogs();
      }
    );

    watch(
      () => productionProcess.value,
      function () {
        getProdlogs();
      }
    );

    watch(
      () => logStatuses.value,
      function (newValue) {
        statuses.value = newValue.data;
      }
    );

    watch(
      () => summaryData.value,
      function (newValue) {
        summary.value = newValue.data;
      }
    );

    watch(
      () => rowsPerPage.value,
      function (newValue) {
        if (newValue) {
          serverOptions.value.rowsPerPage = newValue;
          setTimeout(() => {
            getProdlogs();
          }, 1000);
        }
      }
    );

    watch(
      () => serverOptions.value,
      function () {
        if (!isSearch.value) {
          getProdlogs();
          isSearch.value = false;
        }
      }
    );

    return {
      headers,
      serverOptions,
      items,
      itemsAll,
      loading,
      prodProcess,
      statuses,
      productionProcess,
      statsRangeDate,
      format,
      summary,
      searchValue,
      search,
      resetFilters,
      updateSort,
      isSearch,
      rowsPerPage,
    };
  },
  computed: {},
};
</script>
